import React, {useEffect, useState} from 'react'
import {useAuth} from '../../modules/auth'
import {getCourse} from '../my-course/list/core/_requests'
import {getQuiz} from '../my-quiz/list/core/_requests'
import {getVirtualClass} from '../my-virtualclass/list/core/_requests'
import {MediaPlayer} from './mediaPlayer'

const MotivationSentence = () => {
  const {currentUser} = useAuth()

  const [course, setCourse] = React.useState<any>([])
  const [quiz, setQuiz] = React.useState<any>([])
  const [survey, setSurvey] = React.useState<any>([])
  const [vclass, setVclass] = React.useState<any>([])
  useEffect(() => {
    getCourse('').then((res: any) => {
      setCourse(res.data.length)
    })

    getVirtualClass('').then((res: any) => {
      setVclass(res.data.length)
    })

    getQuiz('').then((res: any) => {
      setQuiz(res.data.length)
      const surveys = res.data.filter((item: any) => item.surveyStatus)
      setSurvey(surveys.length)
    })
  }, [])

  return (
    <div className='card mb-5 mb-xl-10 mt-7'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex align-items-center mb-2'>
                <span className='text-gray-800 fs-3 fw-bolder me-1'>Motivasyon Köşesi</span>
              </div>
            </div>

            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex  flex-grow-1 pe-8'>
                <div className='d-flex flex-md-row flex-column row'>
                  <div className='border  col-md-8 col-12 border-gray-300 border-dashed rounded min-w-125px py-3 px-4  mb-3'>
                    <div className='fw-bold  fs-6 text-gray-400 d-flex align-items-center'>
                      <i className={`fa fa-genderless text-warning m-1 fs-1`}></i>
                      <p className='m-0 px-3'>
                        Küçük adımlarla büyük değişimlere ulaşabilir, gücünüzü keşfedin ve
                        hayallerinizi gerçekleştirin. Başarının kapıları sizin için açık!
                      </p>
                    </div>
                  </div>
                  <div className='col-md-4 col-12 mt-md-0 my-3 justify-center text-center'>
                    <MediaPlayer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {MotivationSentence}
