import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {Tab} from '../Tabs/Tab'
import {ChartsWidgetGeneral} from '../../../../_metronic/partials/widgets/charts/ChartsWidgetGeneral'
import {getQuizbyUserId, getGeneralQuiz} from '../_requests'
import qs from 'qs'
import {ChartQuiz} from '../../../../_metronic/partials/widgets/charts/ChartsQuiz'
import {ChartsBridge} from './ChartBridge'
import {UserQuizCharts} from '../../../../_metronic/partials/widgets/charts/NewUserQuizWidget1'
import {classNames} from '@react-pdf-viewer/core'

const Quiz: FC = () => {
  //   const [data, setData] = useState<any>(undefined)
  const [groupedData, setGroupedData] = useState<any>([])
  const [categories, setCategories] = useState<any>(undefined)
  const [generalData, setGeneralData] = useState<any>([])

  const intl = useIntl()

  useEffect(() => {
    const qsd = qs.parse(window.location.search, {ignoreQueryPrefix: true}).userId ?? undefined

    // getGeneralQuiz(qsd).then((res: any) => {
    //   console.log(res.data)
    //   setGeneralData(res.data)
    // })

    getGeneralQuiz(qsd).then((res2: any) => {
      res2.data.forEach((item: any) => {
        const courseTermId = item.courseTermId

        console.log(groupedData)
        if (!groupedData[courseTermId]) {
          console.log('xx')
          groupedData[courseTermId] = []
        }
        groupedData[courseTermId].push(item)
      })

      const groupedDataArray = Object.entries(groupedData).map(([key, value]) => ({
        courseTermId: Number(key),
        data: value,
      }))

      setGroupedData(groupedDataArray)
    })
  }, [])

  console.log(groupedData)
  console.log(groupedData)

  return (
    <>
      <PageTitle>{intl.formatMessage({id: 'USERCOURSEREPORTS'})}</PageTitle>

      <Tab />
      <div className='row'>
        {groupedData.length &&
          groupedData?.map((course: any, index: number) => {
            const average = course?.data?.map((item2: any) => item2.average)
            const passMark = course?.data?.map((item2: any) => item2.passMark)
            const quizName = course?.data?.map((item2: any) => item2.quizName)
            const accordionId = `accordion_${index}`
            const headerId = `accordion_header_${index}`
            const bodyId = `accordion_body_${index}`
            return (
              <div className='accordion' id={accordionId} key={index}>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id={headerId}>
                    <button
                      className='accordion-button fs-4 fw-bold collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target={`#${bodyId}`}
                      aria-expanded='false'
                      aria-controls={bodyId}
                    >
                      {course?.data[0].courseTermName}
                    </button>
                  </h2>
                  <div
                    id={bodyId}
                    className='accordion-collapse collapse'
                    aria-labelledby={headerId}
                    data-bs-parent={`#${accordionId}`}
                  >
                    <div className='accordion-body'>
                      <div className='row  gap-16 d-flex justify-content-center'>
                        <div className='accordion-body'>
                          <UserQuizCharts
                            className={''}
                            average={average}
                            quizName={quizName}
                            passMark={passMark}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
      </div>

      {/* {groupedData.map((term: any) => {
        const courseTermId = term.courseTermId
        const data = term.data?.map((quiz: any) => quiz.passMark) || []
        const categories = term.data?.map((quiz: any) => quiz.quizName) || []
        const quizPassMark = term.data?.map((quiz: any) => quiz.quizPassMark) || []
        return (
          <ChartsBridge
            className=''
            data={data}
            categories={categories}
            courseTermId={courseTermId}
            quizPassMark={quizPassMark}
          />
        )
      })} */}
    </>
  )
}

export {Quiz}
