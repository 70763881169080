/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import qs from 'qs'
import {Tab} from '../report-tabs/Tab'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {TopicChartsBridge} from './topicChartBridge'
import {getTopicReport} from '../Requests/_request'

export function TopicReport() {
  const intl = useIntl()
  const [data, setData] = useState<any>([])

  useEffect(() => {
    getTopicReport().then((res: any) => {
      console.log(res)
      setData(res.data)
    })
  }, [])

  console.log(data)
  return (
    <>
      <PageTitle>{intl.formatMessage({id: 'General_Reports'})}</PageTitle>
      <Tab />

      {data?.map((course: any, index: number) => {
        const courseId = course.courseId
        const topicName = course?.topics?.map((item: any) => item.topic) || []
        const percentage = course?.topics?.map((item: any) => item.percentage) || []
        const accordionId = `accordion_${index}`
        const headerId = `accordion_header_${index}`
        const bodyId = `accordion_body_${index}`

        return (
          <div className='accordion' id={accordionId} key={index}>
            <div className='accordion-item'>
              <h2 className='accordion-header' id={headerId}>
                <button
                  className='accordion-button fs-4 fw-bold collapsed'
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target={`#${bodyId}`}
                  aria-expanded='false'
                  aria-controls={bodyId}
                >
                  {course.courseName}
                </button>
              </h2>
              <div
                id={bodyId}
                className='accordion-collapse collapse'
                aria-labelledby={headerId}
                data-bs-parent={`#${accordionId}`}
              >
                <div className='accordion-body'>
                  <TopicChartsBridge
                    className=''
                    topicName={topicName}
                    percentage={percentage}
                    courseId={courseId}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}
