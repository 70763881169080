import React, {useState, useEffect} from 'react'
import {ChartTotalTopic} from '../../../../_metronic/partials/widgets/charts/ChartsTotalTopic'
import {getCourseById} from '../../course/list/core/_requests'
import {ChartTotalQuiz} from '../../../../_metronic/partials/widgets/charts/ChartTotalQuiz'
import {getCourseTermById} from '../../courseterm/list/core/_requests'
import {ChartsTotalCourse} from '../../../../_metronic/partials/widgets/charts/ChartsTotalCourse'

type Props = {
  className: string
  termName: any
  quizName: any
  passedUser: any
  totalUser: any
  failedUser: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CourseBridge = ({
  className,
  passedUser,
  termName,
  quizName,
  totalUser,
  failedUser,
}: Props) => {
  //   const [courseTermName, setCourseName] = useState()
  //   useEffect(() => {
  //     getCourseTermById(courseTermId).then((res: any) => {
  //       setCourseName(res.name)
  //     })
  //   }, [])
  console.log(passedUser)
  return (
    <ChartsTotalCourse
      className=''
      termName={termName}
      quizName={quizName}
      totalUser={totalUser}
      passedUser={passedUser}
      failedUser={failedUser}
    />
  )
}

export {CourseBridge}
