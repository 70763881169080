import React, {useState, useEffect} from 'react'
import {ChartTotalTopic} from '../../../../_metronic/partials/widgets/charts/ChartsTotalTopic'
import {getCourseById} from '../../course/list/core/_requests'
import {ChartTotalQuiz} from '../../../../_metronic/partials/widgets/charts/ChartTotalQuiz'
import {getCourseTermById} from '../../courseterm/list/core/_requests'

type Props = {
  className: string
  name: any
  value: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const TotalQuizBridge = ({className, name, value}: Props) => {
  const [courseTermName, setCourseName] = useState()

  console.log(name, value)

  return <ChartTotalQuiz className='' name={name} value={value} />
}

export {TotalQuizBridge}
