import React, {FC} from 'react'
import {Document, Page, Text, View, StyleSheet, Image, Font} from '@react-pdf/renderer'
import HeasImg from '../cert/Images/heas_cert.png'

// Register Font
Font.register({
  family: 'Roboto',
  src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
})

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
  },
  pageBackground: {
    position: 'relative',
    display: 'flex',
    height: 'auto',
    width: 'auto',
  },
  name: {
    position: 'absolute',
    top: '220px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
  },
  nameHeas: {
    position: 'absolute',
    top: '240px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
  },
  identity: {
    position: 'absolute',
    top: '275px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
  },
  code: {
    position: 'absolute',
    top: '135px',
    right: '50px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '200px',
  },
  codeHeas: {
    position: 'absolute',
    top: '100px',
    right: '30px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '200px',
  },
  signer: {
    position: 'absolute',
    bottom: '96px',
    right: '102px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '200px',
  },
  signerHeas: {
    position: 'absolute',
    bottom: '80px',
    right: '102px',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '200px',
  },
  duration: {
    position: 'absolute',
    bottom: '134px',
    left: '234px',
    width: '200px',
  },
  durationHeas: {
    position: 'absolute',
    bottom: '120px',
    left: '234px',
    width: '200px',
  },

  validuntil: {
    position: 'absolute',
    bottom: '74px',
    left: '270px',
    width: '200px',
  },
  date: {
    position: 'absolute',
    bottom: '105px',
    left: '345px',
    width: '200px',
  },
  dateHeas: {
    position: 'absolute',
    bottom: '104px',
    left: '234px',
    width: '200px',
  },
  nameText: {},
  coursenameText: {
    position: 'absolute',
    bottom: '215px',
    width: '100%',
    flexWrap: 'nowrap',
  },
})
type Props = {
  name: string
  date: string
  duration: string
  validuntil: string
  coursename: string
  identity: string
  code: string
  signer: string
  tenant: string
}
// Create Document Component
const MyDocument: FC<Props> = ({
  name,
  date,
  duration,
  validuntil,
  coursename,
  identity,
  code,
  signer,
  tenant,
}) => (
  <>
    {name ? (
      // bu koşul tenanta göre ayarlanacak
      <Document>
        <Page size='A4' orientation='landscape' style={styles.page}>
          <View style={{flexDirection: 'row'}}>
            <Image
              // src={'http://localhost:3011/media/cert/heas_cert.png'}
              src={HeasImg}
              style={styles.pageBackground}
            />
            <View style={styles.nameHeas}>
              <Text style={{fontSize: '25px', display: 'flex', alignSelf: 'center'}}>{name}</Text>
            </View>
            <View style={styles.identity}>
              <Text style={{fontSize: '15px', display: 'flex', alignSelf: 'center'}}>
                {identity}
              </Text>
            </View>

            <View style={styles.durationHeas}>
              <Text style={{fontSize: '12px'}}>{duration} Dakika</Text>
            </View>

            <View style={styles.codeHeas}>
              <Text style={{fontSize: '10px', display: 'flex', alignSelf: 'center'}}>{code}</Text>
            </View>
            <View style={styles.signerHeas}>
              <Text>Muhammed Vefa ARIKAN</Text>
            </View>
            <View style={styles.dateHeas}>
              <Text style={{fontSize: '12px'}}>{date}</Text>
            </View>
            <View wrap={false} style={styles.coursenameText}>
              <Text style={{width: '80%', alignSelf: 'center'}}>{coursename}</Text>
            </View>
          </View>
        </Page>
      </Document>
    ) : (
      <Document>
        <Page size='A4' orientation='landscape' style={styles.page}>
          <View style={{flexDirection: 'row'}}>
            <Image
              src={`http://localhost:3011/media/cert/` + tenant + `_cert.png`}
              style={styles.pageBackground}
            />

            <View style={styles.name}>
              <Text style={{fontSize: '35px', display: 'flex', alignSelf: 'center'}}>{name}</Text>
            </View>
            <View style={styles.identity}>
              <Text style={{fontSize: '35px', display: 'flex', alignSelf: 'center'}}>
                {identity}
              </Text>
            </View>

            <View style={styles.duration}>
              <Text>{duration}</Text>
            </View>
            <View style={styles.validuntil}>
              <Text>{validuntil}</Text>
            </View>
            <View style={styles.code}>
              <Text style={{fontSize: '10px', display: 'flex', alignSelf: 'center'}}>{code}</Text>
            </View>
            <View style={styles.signer}>
              <Text>{signer}</Text>
            </View>
            <View style={styles.date}>
              <Text>{date}</Text>
            </View>
            <View wrap={false} style={styles.coursenameText}>
              <Text style={{width: '80%', alignSelf: 'center'}}>{coursename}</Text>
            </View>
          </View>
        </Page>
      </Document>
    )}
  </>
)

export default MyDocument
