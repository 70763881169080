import React, {useEffect, useState} from 'react'
import {useAuth} from '../../modules/auth'
import {getCourse} from '../my-course/list/core/_requests'
import {getQuiz} from '../my-quiz/list/core/_requests'
import {getVirtualClass} from '../my-virtualclass/list/core/_requests'
const UserInfo = () => {
  const {currentUser} = useAuth()
  console.log(currentUser)
  const [course, setCourse] = React.useState<any>([])
  const [quiz, setQuiz] = React.useState<any>([])
  const [survey, setSurvey] = React.useState<any>([])
  const [vclass, setVclass] = React.useState<any>([])
  useEffect(() => {
    getCourse('').then((res: any) => {
      setCourse(res.data.length)
    })

    getVirtualClass('').then((res: any) => {
      setVclass(res.data.length)
    })

    getQuiz('').then((res: any) => {
      setQuiz(res.data.length)
      const surveys = res.data.filter((item: any) => item.surveyStatus)
      setSurvey(surveys.length)
    })
  }, [])
  console.log(survey)
  return (
    <div className='card mb-5 mb-xl-10 mt-7'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <span className='text-gray-800 fs-3 fw-bolder me-1'>
                    {currentUser?.fullName} Kullanıcısına Ait Bilgiler
                  </span>
                </div>
              </div>
            </div>

            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-column'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-2 fw-bolder'>{course}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Kurs</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-2 fw-bolder'>{quiz}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Sınav</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-2 fw-bolder'>{survey}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Anket</div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-2 fw-bolder'>{vclass}</div>
                    </div>

                    <div className='fw-bold fs-6 text-gray-400'>Canlı Ders</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export {UserInfo}
