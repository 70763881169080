import React, {useEffect, useState} from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import * as am4plugins_sunburst from '@amcharts/amcharts4/plugins/sunburst'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import {getAllJourney} from '../_requests'
import {getTestScore, getTestScore2} from '../_requests'
import {listInventoryTest} from '../../inventorytest/list/core/_requests'
import {useIntl} from 'react-intl'
import qs from 'qs'
import {TestPercentage} from './TestPercentageReport'

am4core.useTheme(am4themes_animated)

const AmCharts3 = () => {
  const [data, setData] = useState<any>(undefined)
  const [test, setTest] = useState<any>(undefined)
  const [comments, setComments] = useState<any>(undefined)
  const intl = useIntl()

  useEffect(() => {
    const qsd = qs.parse(window.location.search, {ignoreQueryPrefix: true}).userId ?? undefined

    getTestScore2(qsd).then((res: any) => {
      //   setData(res?.data[0])
      console.log(res)
      if (res.data[0].scoreByDimensionList != null) {
        const newData = res.data[0].scoreByDimensionList.map((item: any) => ({
          category: item.dimensionName,
          value: (item.score * 100) / item.maxScore,
          full: 100,
        }))
        setData(newData)

        const newComment = res.data[0].scoreByDimensionList.map((item: any) => ({
          commentType: item.commentType,
          dimensionComment: item.dimensionComment,
          score: item.score,
          dimensionName: item.dimensionName,
        }))

        setComments(newComment)
      }

      if (res.data[0].upperDimensions != null) {
        const newData = res.data[0].upperDimensions.map((item: any) => ({
          category: item.upperDimensionName,
          value: (item.upperDimensionScore * 100) / item.maxScore,
          full: 100,
        }))
        setData(newData)
        const newComment = res.data[0].upperDimensions.map((item: any) => ({
          commentType: item.commentType,
          dimensionComment: item.dimensionComment,
          score: item.score,
          dimensionName: item.dimensionName,
        }))

        setComments(newComment)
      }
    })

    listInventoryTest('Id,Name').then((res: any) => {
      setTest(res.data)
    })
  }, [])

  console.log(comments)
  const getChart = (testId: any) => {
    const qsd = qs.parse(window.location.search, {ignoreQueryPrefix: true}).userId ?? undefined

    getTestScore(qsd, testId).then((res: any) => {
      //   setData(res?.data[0])
      console.log(res)
      if (res.data[0].scoreByDimensionList != null) {
        const newData = res.data[0].scoreByDimensionList.map((item: any) => ({
          category: item.dimensionName,
          value: (item.score * 100) / item.maxScore,
          full: 100,
        }))
        setData(newData)
        const newComment = res.data[0].scoreByDimensionList.map((item: any) => ({
          commentType: item.commentType,
          dimensionComment: item.dimensionComment,
          score: item.score,
          dimensionName: item.dimensionName,
        }))

        setComments(newComment)
      }

      if (res.data[0].upperDimensions != null) {
        const newData = res.data[0].upperDimensions.map((item: any) => ({
          category: item.upperDimensionName,
          value: (item.upperDimensionScore * 100) / item.maxScore,
          full: 100,
        }))
        setData(newData)
        const newComment = res.data[0].upperDimensions.map((item: any) => ({
          commentType: item.commentType,
          dimensionComment: item.dimensionComment,
          score: item.score,
          dimensionName: item.dimensionName,
        }))

        setComments(newComment)
      }
    })
  }

  useEffect(() => {
    // Create chart instance
    if (2 == 2) {
      let chart: any = am4core.create('chartdiv2', am4charts.RadarChart)
      console.log(data)
      // Add data
      chart.data = data
      // chart.data = [
      //   {
      //     category: 'Research',
      //     value: 80,
      //     full: 100,
      //   },
      //   {
      //     category: 'Marketing',
      //     value: 35,
      //     full: 100,
      //   },
      //   {
      //     category: 'Distribution',
      //     value: 92,
      //     full: 100,
      //   },
      //   {
      //     category: 'Human Resources',
      //     value: 68,
      //     full: 100,
      //   },
      // ]

      // Make chart not full circle
      chart.startAngle = -90
      chart.endAngle = 180
      chart.innerRadius = am4core.percent(20)

      // Set number format
      chart.numberFormatter.numberFormat = "#.#'%'"

      // Create axes
      let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis())
      categoryAxis.dataFields.category = 'category'
      categoryAxis.renderer.grid.template.location = 0
      categoryAxis.renderer.grid.template.strokeOpacity = 0
      categoryAxis.renderer.labels.template.horizontalCenter = 'right'
      categoryAxis.renderer.labels.template.fontWeight = 500
      categoryAxis.renderer.labels.template.adapter.add('fill', function (fill: any, target: any) {
        return target.dataItem.index >= 0 ? chart.colors.getIndex(target.dataItem.index) : fill
      })
      categoryAxis.renderer.minGridDistance = 10

      let valueAxis = chart.xAxes.push(new am4charts.ValueAxis())
      valueAxis.renderer.grid.template.strokeOpacity = 0
      valueAxis.min = 0
      valueAxis.max = 100
      valueAxis.strictMinMax = true

      // Create series
      let series1 = chart.series.push(new am4charts.RadarColumnSeries())
      series1.dataFields.valueX = 'full'
      series1.dataFields.categoryY = 'category'
      series1.clustered = false
      series1.columns.template.fill = new am4core.InterfaceColorSet().getFor(
        'alternativeBackground'
      )
      series1.columns.template.fillOpacity = 0.08
      series1.columns.template.cornerRadiusTopLeft = 20
      series1.columns.template.strokeWidth = 0
      series1.columns.template.radarColumn.cornerRadius = 20

      let series2 = chart.series.push(new am4charts.RadarColumnSeries())
      series2.dataFields.valueX = 'value'
      series2.dataFields.categoryY = 'category'
      series2.clustered = false
      series2.columns.template.strokeWidth = 0
      series2.columns.template.tooltipText = '{category}: [bold]{value}[/]'
      series2.columns.template.radarColumn.cornerRadius = 20

      series2.columns.template.adapter.add('fill', function (fill: any, target: any) {
        return chart.colors.getIndex(target?.dataItem?.index)
      })

      // Add cursor
      chart.cursor = new am4charts.RadarCursor()

      // Clean up on unmount
      return () => {
        chart.dispose()
      }
    }
  }, [data])

  return (
    <>
      {' '}
      <div className='d-flex  flex-column border border-primary flex-md-row justify-content-between card p-5'
        
      >
        <div className='col-md-12 col-12 '>
          <TestPercentage />
          <br></br>
          {/* <label className='required fw-bold fs-6 mb-2 '>Test Seçiniz</label> */}
          <select
            className='form-select form-select-solid form-select-md '
            onChange={(e: any) => {
              getChart(e.target.value)
            }}
            // value={formik.values.inventoryTypeId}
          >
            <option value=''>{intl.formatMessage({id: 'Raporunu görmek için bir test seçiniz...'})}</option>
            {test?.map((myval: any) => (
              <option value={myval?.id} key={myval?.id as any}>
                {myval?.name as any}
              </option>
            ))}
          </select>
          
          
        </div>
        
        
        

      

      {' '}
      </div>
      <div className='d-flex flex-column flex-md-row justify-content-between card p-5 m-5'>

      
      <div className='col-xl-6'>
      <div id='chartdiv2' style={{width: '100%', height: '500px'}}></div>
      </div>
      
      
      <div className='col-xl-6'>
      {comments?.map((item: any, index: number) => {
        const accordionId = `accordion_${index}`
        const headerId = `accordion_header_${index}`
        const bodyId = `accordion_body_${index}`
        return (
          <div className='accordion' id={accordionId} key={index}>
            <div className='accordion-item '>
              <h2 className='accordion-header ' id={headerId}>
                <button
                  className='accordion-button fs-4 fw-bold collapsed '
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target={`#${bodyId}`}
                  aria-expanded='false'
                  aria-controls={bodyId}
                >
                  {item?.dimensionName}
                </button>
              </h2>
              <div
                id={bodyId}
                className='accordion-collapse collapse'
                aria-labelledby={headerId}
                data-bs-parent={`#${accordionId}`}
              >
                <div className='accordion-body'>
                  <div className='row  gap-16 d-flex justify-content-center '>
                    <div className='accordion-body'>
                      <div className='d-flex flex-column  row '>
                        <div className='d-flex  col-6  '>
                          <div className='d-flex align-items-center  gap-4'>
                            <div className='flex-grow-1 me-2'>
                              <span className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                                Alınan puan
                              </span>
                            </div>
                            <span
                              className='badge fw-bold my-2'
                              style={{backgroundColor: '#dc67ce', color: '#fff'}}
                            >
                              {item.score}
                            </span>
                            <span className='text-gray-800 text-hover text-danger fs-6 fw-bold'>
                              {item.commentType}
                            </span>
                          </div>
                        </div>
                        <div className='d-flex  col-12 gap-2  mt-5 '>
                          <span className='fw-bold'>Yorum:</span> {item?.dimensionComment}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
      </div>
      </div>
      

      
    </>
  )
}

export {AmCharts3}
