import React, { useState, useEffect, useRef } from "react";
import ScormProvider, { withScorm } from "react-scorm-provider";
import { parseStringPromise } from 'xml2js';
const API_URL = process.env.REACT_APP_API_URL


async function parseManifestFile(manifestContent) {
    try {
        const result = await parseStringPromise(manifestContent, { trim: true });
        const resources = result.manifest.resources[0].resource;

        console.log(resources);

        const launchUrlResource = resources.find(
            (resource) => resource.$['adlcp:scormtype'] === 'sco'
        );

        console.log(launchUrlResource.$.href);

        if (!launchUrlResource) {
            throw new Error('SCORM launch URL not found in the manifest file');
        }

        return launchUrlResource.$.href;
    } catch (error) {
        console.error('Error parsing manifest file:', error);
        return null;
    }
}

const ScormPlayer = ({ sco, launchUrl }) => {
    const adapterIframeRef = useRef(null);
    const [loaded, setLoaded] = useState(false);
    const [baseUrl, setBaseUrl] = useState(false);

    useEffect(() => {
        if (launchUrl) {
            //const adapterIframe = adapterIframeRef.current.contentWindow;
            // SCORM API'yi ve paket URL'sini adapter.html'e g�nderin
            //adapterIframe.initializeAPI(window.API);
            //adapterIframe.loadSCORMPackage(launchUrl);
            setLoaded(true);
            setBaseUrl(`${(API_URL || "").slice(0, -4)}/Files/scorm/adapter.html`);
            //window.open(launchUrl, "SCORM Player", "width=1000,height=600");
            console.log(sco)
            console.log(window.API)
        }
    }, [sco, launchUrl]);

    const handleIframeLoad = () => {
        if (!adapterIframeRef.current) return;

        const adapterIframe = adapterIframeRef.current.contentWindow;

        // SCORM API'yi ve paket URL'sini adapter.html'e postMessage ile g�nderin
        //adapterIframe.postMessage({ action: 'initializeAPI', payload: window.API }, '*');
        adapterIframe.postMessage({ action: 'loadSCORMPackage', payload: launchUrl }, '*');
    };

    return (
        <div>
            {launchUrl && sco.apiConnected == true && loaded == true ? (
                <div>
                   
                    <iframe onLoad={handleIframeLoad} ref={adapterIframeRef} src={baseUrl} width="1000px" height="600px" frameBorder="0"></iframe>
                    {/*<iframe src={launchUrl} width="1000" height="600" sandbox="allow-scripts allow-same-origin allow-modals allow-popups" allow="fullscreen; clipboard-write; encrypted-media; picture-in-picture"/>*/}
                </div>
            ) : (
                <p>Status: Not connected</p>
            )}
        </div>
    );
};

const ScormPlayerWithScorm = withScorm()(ScormPlayer);

const ScormApp = ({ manifestUrl }) => {
    const [launchUrl, setLaunchUrl] = useState(null);
    useEffect(() => {
        if (manifestUrl != "") {
            const fetchManifestAndParse = async () => {
                try {
                    const response = await fetch(manifestUrl);
                    const manifestContent = await response.text();
                    const launchUrlFromManifest = await parseManifestFile(manifestContent);
                    console.log(manifestUrl.replace('imsmanifest.xml', '') + launchUrlFromManifest);
                    setLaunchUrl(manifestUrl.replace('imsmanifest.xml', '') + launchUrlFromManifest);
                    //setLoaded(true);
                } catch (error) {
                    console.error('Error fetching or parsing manifest file:', error);
                }
            };

            fetchManifestAndParse();
        }
    }, [manifestUrl]);

    return (
        launchUrl != null ?
            <ScormProvider>
            <ScormPlayerWithScorm launchUrl={launchUrl} />
        </ScormProvider> : <></>
    );
};

export default ScormApp;
