/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import {getSurveybyUserId} from '../_requests'
import qs from 'qs'
import {Tab} from '../Tabs/Tab'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {SurveyChartsBridge} from './SurveyChartBridge'
export function Survey() {
  const intl = useIntl()
  const [data, setData] = useState<any>([])

  useEffect(() => {
    const qsd = qs.parse(window.location.search, {ignoreQueryPrefix: true}).userId ?? undefined
    getSurveybyUserId(qsd).then((res: any) => {
      setData(res.data)
    })
  }, [])
  console.log(data)
  return (
    <>
      <PageTitle>{intl.formatMessage({id: 'USERCOURSEREPORTS'})}</PageTitle>
      <Tab />
      <div className='row'>
        {data.length &&
          data?.map((term: any, index: number) => {
            const accordionId = `accordion_${index}`
            const headerId = `accordion_header_${index}`
            const bodyId = `accordion_body_${index}`
            console.log(term)
            return (
              <div className='accordion' id={accordionId} key={index}>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id={headerId}>
                    <button
                      className='accordion-button fs-4 fw-bold collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target={`#${bodyId}`}
                      aria-expanded='false'
                      aria-controls={bodyId}
                    >
                      {term.courseTermName}
                    </button>
                  </h2>
                  <div
                    id={bodyId}
                    className='accordion-collapse collapse'
                    aria-labelledby={headerId}
                    data-bs-parent={`#${accordionId}`}
                  >
                    <div className='d-flex flex-md-row flex-column'>
                      {term && term.userSurveyReportViewModels
                        ? (() => {
                            const newData: any = []
                            term.userSurveyReportViewModels.map((quiz: any) => {
                              const foundCourse = newData.find(
                                (item: any) => item?.courseId === quiz?.courseId
                              )
                              if (foundCourse) {
                                foundCourse.survey.push(quiz)
                              } else {
                                const newCourse = {
                                  courseId: quiz.courseId,
                                  courseName: quiz.courseName,
                                  survey: [quiz],
                                }
                                newData.push(newCourse)
                              }
                            })

                            console.log(newData)

                            const veri = newData?.map((item: any, index2: any) => {
                              const courseName = item.courseName
                              const surveyName = item.survey[0].surveyName
                              const answer =
                                item.survey[0]?.surveyAnswers?.map(
                                  (answer: any) => answer.answer
                                ) || []
                              const sQuestion =
                                item.survey[0]?.surveyAnswers?.map(
                                  (answer: any) => answer.sQuestion
                                ) || []

                              return (
                                <SurveyChartsBridge
                                  className=''
                                  courseName={courseName}
                                  answer={answer}
                                  surveyName={surveyName}
                                  sQuestion={sQuestion}
                                />
                              )
                            })
                            return veri
                          })()
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}

        {/* {data?.map((item: any) => {
          const courseName = item.courseName
          const surveyName = item.surveyName
          const answer = item.surveyAnswers?.map((answer: any) => answer.answer) || []
          const sQuestion = item.surveyAnswers?.map((answer: any) => answer.sQuestion) || []
          return (
            <SurveyChartsBridge
              className=''
              courseName={courseName}
              answer={answer}
              surveyName={surveyName}
              sQuestion={sQuestion}
            />
          )
        })} */}
      </div>
    </>
  )
}
