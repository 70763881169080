/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState, useEffect} from 'react'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import DualListBox from 'react-dual-listbox'
import 'react-dual-listbox/lib/react-dual-listbox.css'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import SCORM_API_adapter from '../../../_metronic/scorm/SCORM_API_adapter'
import {useIntl} from 'react-intl'
import qs from 'qs'
import PdfLayout from '../../modules/apps/pdf/pdflayout'
import VideoLayout from '../../modules/apps/video/videolayout'
import ScormApp from '../../modules/apps/scorm/SCORMPlayer2'
import Vimeo from '@u-wave/react-vimeo';
import ReactPlayer from 'react-player';


import {
  getLessonUrl,
  setLessonPosition,
  getLessonPosition,
  getLessonType,
  setLessonFinish,
} from './list/core/_requests'
import {Document, Page, Outline} from 'react-pdf'
// Core viewer
//var Vimeo = require('react-vimeo');
// Create new plugin instance
const API_URL = process.env.REACT_APP_API_URL

interface VimeoPlayerProps {
  url: string;
}

const VimeoPlayer: React.FC<VimeoPlayerProps> = ({ url }) => {
  return (
    <div className='player-wrapper'>
      <ReactPlayer
        url={url}
        className='react-player'
        width='1200px'
        controls={true}
      />
    </div>
  );
};

export default VimeoPlayer;


const Breadcrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },

  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
declare global {
  interface Window {
    API: object
  }
}
interface Props {
   setLessonFinish: Function;
}




const LessonViewer: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  window.API = SCORM_API_adapter
  const [searchParams, setSearchParams] = useSearchParams()

  const [lid, setLid] = React.useState(searchParams.get('lid'))
  const [lessonUrl, setLessonUrl] = React.useState('')
  const [scormLessonUrl, setScormLessonUrl] = React.useState('')
  const [lfButton, setLfButton] = React.useState(true)
  const [lP, setLP] = React.useState(1)
  const [type, setType] = React.useState<any>(undefined)
  
  
  
  function setLessonP(position: any) {
    console.log(position)
    setLessonPosition(parseInt(lid || '0'), position)
    setLP(position)
  }

  function setLessonF() {
    setLessonFinish(parseInt(lid || '0')).then(() => {
      navigate('/my-course')
    })
  }

  useEffect(() => {
    getLessonUrl(parseInt(lid || '0')).then((res: any) => {
      //let sp = JSON.parse(res)
      //console.log(res)
      setLfButton(res.Ended > '2020-01-01' ? false : true)
      setLessonUrl(res.lesson.file)
      setScormLessonUrl(`${(API_URL || '').slice(0, -4)}/${res.lesson.file}/imsmanifest.xml`)
      //console.log(`${(API_URL || "").slice(0, -4)}/${res.lesson.file}/imsmanifest.xml`)
      getLessonPosition(parseInt(lid || '0')).then((res: any) => {
        //console.log(res)
        if (res > 0) setLP(res)
      })
    })

    getLessonType(parseInt(lid || '0')).then((res: any) => {
      //console.log(res)
      setType(res)
    })
  }, [lid])

  console.log(type)

  return (
    <div className='d-flex flex-row row '>
      <div className='col-1'></div>
      <div className='col-10'>
        {type?.description == 'PDF' ? (
          <PdfLayout
            lfButton={lfButton}
            fileUrl={lessonUrl}
            setLessonPosition={setLessonP}
            lessonPosition={lP}
            setLessonFinish={setLessonF}
          />
        ) : type?.description == 'Video' ? (
          // <VideoLayout
          //   lfButton={lfButton}
          //   fileUrl={lessonUrl}
          //   setLessonPosition={setLessonP}
          //   lessonPosition={lP}
          //   setLessonFinish={setLessonF}
          // />
          // <Vimeo videoId={ '893703421' } autoplay={true} progressbar={true} />
          // <Vimeo
          //   video={ lessonUrl }
          //   // style={{width: '100%'}}
          //   width={"1000px"}
          //   showPortrait
          //   responsive
          // />
<VimeoPlayer url={`https://vimeo.com/${lessonUrl}`}  />

          
        ) : type?.description == 'Scorm' ? (
          <ScormApp manifestUrl={scormLessonUrl} />
        ) : (
          <div></div>
        )}
      </div>
      <div>
           <button
            type='button'
            style={{ width: "200px", position: "fixed", bottom: "65px", right: "165px" }}
            className='btn btn-sm btn-dark btn-active-light-dark mt-3 mb-3'
            onClick={setLessonF}
           >
            {'Dersi Bitir'}
          </button>

          </div>
      <div className='col-1'></div>
    </div>
  )
}

export {LessonViewer}
