import React, {useState, useEffect} from 'react'
import {ChartQuiz} from '../../../../_metronic/partials/widgets/charts/ChartsQuiz'
import {getCourseTermById} from '../../courseterm/list/core/_requests'
import {UserCourseQuizCharts} from '../../../../_metronic/partials/widgets/charts/NewUserCourseQuiz'

type Props = {
  className: string
  average: any
  quizName: any
  passMark: any
  courseName: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const ChartsBridge = ({className, average, quizName, passMark, courseName}: Props) => {
  return (
    <div className=' '>
      <UserCourseQuizCharts
        className=''
        average={average}
        quizName={quizName}
        passMark={passMark}
        courseName={courseName}
      />
    </div>
  )
}

export {ChartsBridge}
