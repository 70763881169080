import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {KTIcon} from '../../../_metronic/helpers'
import React, {useState, useRef, useEffect} from 'react'
import {CustomNotification} from './_requests'
import {useAuth} from '../../modules/auth'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'

const MultiNotification = () => {
  const {currentUser} = useAuth()
  const [title, setTitle] = useState<any>('')
  const [text, setText] = useState<any>('')
  const intl = useIntl()

  const SendNotification = () => {
    CustomNotification({title: title, text: text, tenantId: currentUser?.tenantId}).then(
      (res: any) => {
        setText('')
        setTitle('')
        if (res.status == 200) {
          Swal.fire({
            title: intl.formatMessage({id: 'SWEETALERT.SUCCESS'}),
            // text: intl.formatMessage({id: 'SWEETALERT.DELETED'}),
            icon: 'success',
            timer: 2000,
            showConfirmButton: true,
          }).then(() => {})
        } else {
          Swal.fire({
            title: intl.formatMessage({id: 'SWEETALERT.FAIL'}),
            // text: intl.formatMessage({id: 'SWEETALERT.DELETED'}),
            icon: 'warning',
            timer: 2000,
            showConfirmButton: true,
          })
        }
      }
    )
  }
  return (
    <>
      <div className={`card mt-16`}>
        {/* begin::Body */}
        <div className='card-body pb-0'>
          {/* begin::Header */}
          <div className='d-flex align-items-center mb-5'>
            {/* begin::User */}
            <div className='d-flex align-items-center flex-grow-1'>
              {/* begin::Avatar */}
              {/* <div className='symbol symbol-45px me-5'>
                <img src={toAbsoluteUrl('/media/avatars/300-23.jpg')} alt='' />
              </div> */}
              {/* end::Avatar */}

              {/* begin::Info */}
              <div className='d-flex flex-column w-100'>
                <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                  Toplu Bildirim
                </a>

                <span className='text-gray-400 fw-semibold'>
                  Toplu bildirim göndermek için başlık ve içerik girip ardından butona basınız
                </span>

                <div className='d-flex flex-column  mt-7 justify-center alings-item-center '>
                  <div className='fv-row  col-6'>
                    <label className=' fw-bold fs-6 mb-2'>Başlık</label>
                    <input
                      type='text'
                      name='content'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>

                  <div className='fv-row mt-7  col-6'>
                    <label className=' fw-bold fs-6 mb-2'>Bildirim İçeriği</label>
                    <textarea
                      rows={4}
                      name='content'
                      value={text}
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      onChange={(e) => setText(e.target.value)}
                      autoComplete='off'
                    />
                  </div>
                </div>
              </div>
              {/* end::Info */}
            </div>
            {/* end::User */}

            {/* begin::Menu */}

            {/* end::Menu */}
          </div>
          {/* end::Header */}

          {/* begin::Post */}
          <div className='mb-5'></div>
          {/* end::Post */}

          {/* begin::Separator */}
          <div className='separator mb-4'></div>
          {/* end::Separator */}

          {/* begin::Reply input */}

          <button
            type='submit'
            className='btn btn-primary mb-6 '
            data-kt-items-modal-action='submit'
            disabled={title == '' || text == ''}
            onClick={(e) => {
              SendNotification()
            }}
          >
            <span className='indicator-label'> Toplu Bildirim Gönder</span>
          </button>

          {/* <div className='position-absolute top-0 end-0 me-n5'>
              <span className='btn btn-icon btn-sm btn-active-color-primary pe-0 me-2'>
                <KTIcon iconName='paper-clip' className='fs-3 mb-3' />
              </span>

              <span className='btn btn-icon btn-sm btn-active-color-primary ps-0'>
                <KTIcon iconName='geolocation' className='fs-2 mb-3' />
              </span>
            </div> */}

          {/* edit::Reply input */}
        </div>
        {/* end::Body */}
      </div>
    </>
  )
}

export default MultiNotification
