import axios, {AxiosResponse} from 'axios'
import {ID, parseRequestQuery, Response} from '../../../_metronic/helpers'
import {Model, QueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const THING_URL = `${API_URL}/Custom/getUserClasses`
const USER_URL = `${API_URL}/Auth/get-user-by-id`
const COURSE_URL = `${API_URL}/Custom/getUserCourseReport`
const LESSON_URL = `${API_URL}/Custom/getUserLessonReport`
const QUIZ_URL = `${API_URL}/Custom/getUserQuizReport`
const TOPIC_URL = `${API_URL}/Custom/getUserTopicReport`
const SURVEY_URL = `${API_URL}/Custom/getUserSurveyReport`
const ANSWER_URL = `${API_URL}/Custom/getUserQuizAnswers`
const FINSSHED_COURSE_URL = `${API_URL}/Custom/getCourseFinishedReport`
const USER_LESSON_ACTION_URL = `${API_URL}/Custom/getUserLessonActionReport`
const USER_GENERAL_QUIZ_URL = `${API_URL}/Custom/getUserGeneralQuizReport`
const USER_TEST_PERCENTAGE_URL = `${API_URL}/Custom/getUserTestPercentageReport`
const USER_TEST_SCORE_URL = `${API_URL}/Custom/getUserTestScore`
const USER_STRESS_URL = `${API_URL}/Custom/getUserStressReport`
const USER_ALLTEST_URL = `${API_URL}/Custom/getUserAllTestScore`
const USER_ALLJOURNEY_URL = `${API_URL}/Custom/getUserJourneyReport`

const getThings = (userId: any): Promise<QueryResponse> => {
  return axios
    .get(`${THING_URL}?userId=${userId}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getUserById = (userId: any): Promise<QueryResponse> => {
  return axios
    .post(`${USER_URL}?userId=${userId}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getCoursebyUserId = (userId: any): Promise<QueryResponse> => {
  return axios
    .get(`${COURSE_URL}?userid=${userId}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}
const getLessonbyUserId = (userId: any): Promise<QueryResponse> => {
  return axios
    .get(`${LESSON_URL}?userid=${userId}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}
const getQuizbyUserId = (userId: any): Promise<QueryResponse> => {
  return axios.get(`${QUIZ_URL}?userid=${userId}`).then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getTopicbyUserId = (userId: any): Promise<QueryResponse> => {
  return axios
    .get(`${TOPIC_URL}?userid=${userId}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getSurveybyUserId = (userId: any): Promise<QueryResponse> => {
  return axios
    .get(`${SURVEY_URL}?userid=${userId}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}
const getQuizAnswers = (userId: any, quizId: any): Promise<QueryResponse> => {
  return axios
    .get(`${ANSWER_URL}?userid=${userId}&quizid=${quizId}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

// ..... değişiklikten sonra

const getCourse = (userId: any): Promise<QueryResponse> => {
  return axios
    .get(`${FINSSHED_COURSE_URL}?userid=${userId}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getLessonAction = (userId: any): Promise<QueryResponse> => {
  return axios
    .get(`${USER_LESSON_ACTION_URL}?userid=${userId}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getGeneralQuiz = (userId: any): Promise<QueryResponse> => {
  return axios
    .get(`${USER_GENERAL_QUIZ_URL}?userid=${userId}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getTestPercentage = (userId: any): Promise<QueryResponse> => {
  return axios
    .get(`${USER_TEST_PERCENTAGE_URL}?userid=${userId}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getStressReport = (userId: any): Promise<QueryResponse> => {
  return axios
    .get(`${USER_STRESS_URL}?userid=${userId}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getTestScore = (userId: any, testId: any): Promise<QueryResponse> => {
  return axios
    .get(`${USER_TEST_SCORE_URL}?userid=${userId}&testid=${testId}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}
const getTestScore2 = (userId: any): Promise<QueryResponse> => {
  return axios
    .get(`${USER_TEST_SCORE_URL}?userid=${userId}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getAllTestScore = (userId: any): Promise<QueryResponse> => {
  return axios
    .get(`${USER_ALLTEST_URL}?userid=${userId}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getAllJourney = (userId: any): Promise<QueryResponse> => {
  return axios
    .get(`${USER_ALLJOURNEY_URL}?userid=${userId}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

export {
  getThings,
  getUserById,
  getCoursebyUserId,
  getLessonbyUserId,
  getQuizbyUserId,
  getTopicbyUserId,
  getSurveybyUserId,
  getQuizAnswers,
  getCourse,
  getLessonAction,
  getGeneralQuiz,
  getTestPercentage,
  getTestScore,
  getStressReport,
  getAllTestScore,
  getAllJourney,
  getTestScore2,
}
