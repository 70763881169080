/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useLocation} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'

import moment from 'moment'

const Tab: React.FC = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const cid = searchParams.get('userId')

  const [projectInfo, setProjectInfo] = React.useState<any>([])
  const {currentUser}: any = useAuth()
  console.log(currentUser)
  useEffect(() => {
    // getTermById(cid).then((res: any) => {
    //   console.log(res)
    //   setProjectInfo(res || [])
    // })
  }, [])

  // const newProjectInfo = projectInfo.filter((item) => item.id == cid)
  console.log(projectInfo)

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'></div>
            </div>

            {/* <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-2 fw-bold'>
                         {moment(projectInfo.startDate).format('YYYY-MM-DD')} 
                      </div>
                    </div>

                    <div className='fw-semibold fs-6 text-gray-400'>Başlangıç Tarihi</div>
                  </div>

                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div className='fs-2 fw-bold'>
                         {moment(projectInfo.endDate).format('YYYY-MM-DD')} 
                      </div>
                    </div>

                    <div className='fw-semibold fs-6 text-gray-400'>Bitiş Tarihi</div>
                  </div>

                   <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr066.svg'
                        className='svg-icon-3 svg-icon-success me-2'
                      />
                      <div className='fs-2 fw-bold'>60%</div>
                    </div>

                    <div className='fw-semibold fs-6 text-gray-400'>Success Rate</div>
                  </div> 
                </div>
              </div>

              <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <span className='fw-semibold fs-6 text-gray-400'>Proje Tamamlanma</span>
                  <span className='fw-bold fs-6'>50%</span>
                </div>
                <div className='h-5px mx-3 w-100 bg-light mb-3'>
                  <div
                    className='bg-success rounded h-5px'
                    role='progressbar'
                    style={{width: '50%'}}
                  ></div>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/approvedServicePrice' && 'active')
                }
                to={`/user-report-profile?userId=${cid}`}
              >
                Kullanıcı Bilgileri
              </Link>
            </li>
            {currentUser?.modules.includes('LMS') ? (
              <li className='nav-item'>
                <Link
                  className={`nav-link text-active-primary me-6 ${
                    location.pathname === '/approvedServicePrice' && 'active'
                  }`}
                  to={`/user-report-course?userId=${cid}`}
                >
                  Kurslar
                </Link>
              </li>
            ) : null}
            {currentUser?.modules.includes('LMS') ? (
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/approvedServicePrice' && 'active')
                  }
                  to={`/user-topic-reports?userId=${cid}`}
                >
                  Konular
                </Link>
              </li>
            ) : null}

            {/* <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/approvedServicePrice' && 'active')
                }
                to={`/user-report-lesson?userId=${cid}`}
              >
                Dersler
              </Link>
            </li> */}
            {currentUser?.modules.includes('LMS') && currentUser?.modules.includes('QUIZ') ? (
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/approvedServicePrice' && 'active')
                  }
                  to={`/quizstatus/list?userId=${cid}`}
                >
                  Sınavlar
                </Link>
              </li>
            ) : null}
            {currentUser?.modules.includes('LMS') && currentUser?.modules.includes('QUIZ') ? (
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/projectSponsor' && 'active')
                  }
                  to={`/user-report-quiz?userId=${cid}`}
                >
                  Genel Sınav Grafikleri
                </Link>
              </li>
            ) : null}
            {currentUser?.modules.includes('LMS') && currentUser?.modules.includes('QUIZ') ? (
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/projectSponsor' && 'active')
                  }
                  to={`/user-report-course-quiz?userId=${cid}`}
                >
                  Özel Sınav Grafikleri
                </Link>
              </li>
            ) : null}

            {currentUser?.modules.includes('LMS') && currentUser?.modules.includes('QUIZ') ? (
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/approvedServicePrice' && 'active')
                  }
                  to={`/user-report-survey?userId=${cid}`}
                >
                  Anketler
                </Link>
              </li>
            ) : null}

            {currentUser?.modules.includes('HR') ? (
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/approvedServicePrice' && 'active')
                  }
                  to={`/user-hr-report?userId=${cid}`}
                >
                  HR Raporları
                </Link>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </div>
  )
}

export {Tab}
