import {useMemo, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate, useParams} from 'react-router-dom'
import {
  getThingById,
  getSurveyId,
  getAnswersByQuestionId,
  getCommentsBySurveyId,
} from './list/core/_requests'
import {Survey, SurveyReportQuestion, SurveyReportComment} from './list/core/_models'
import {ButtonGroup} from 'react-bootstrap'
import {ChartsWidgetSurvey} from '../../../_metronic/partials/widgets/charts/ChartsWidgetSurvey'
import qs from 'qs'
import {getQuizById} from '../quiz/list/core/_requests'

const SurveyReportPage = () => {
  const params = useParams()

  const intl = useIntl()
  const [survey, setSurvey] = useState<Survey>()
  const [surveyReportQuestion, setSurveyReportQuestion] = useState<Array<SurveyReportQuestion>>([])
  const [surveyReportComment, setSurveyReportComment] = useState<Array<SurveyReportComment>>()
  const [answerPoints, setAnswerPoints] = useState<Array<number>>()
  const [loading, setLoading] = useState(false)
  const [textReturn, setTextReturn] = useState('')
  const navigate = useNavigate()
  const [total, setTotal] = useState(0)
  const [courseTermId, setCourseTermId] = useState(0)
  const qsd = qs.parse(window.location.search, {ignoreQueryPrefix: true}).quizId ?? undefined
  console.log(qsd)
  useEffect(() => {
    console.log('sayfaya girdi')
    console.log(qsd)
    getSurveyId(qsd).then((id: any) => {
      getThingById(id.surveyId).then((res) => {
        setSurvey(res)
      })
      getQuizById(qsd).then((res2: any) => {
        setCourseTermId(res2.courseId)
      })
    })
  }, [qsd])

  useEffect(() => {
    if (survey?.answerCount != undefined) {
      const ar: Array<number> = []
      for (let i = 0; i < survey.answerCount; i++) {
        ar.push(i + 1)
      }

      setAnswerPoints(ar)

      let i = 0
      const surveyGroupPromises = survey?.surveyGroup?.map((sg) => {
        const sAQAr: Array<SurveyReportQuestion> = []
        if (sg?.surveyQuestion) {
          const questionPromises = sg.surveyQuestion.map((sq) => {
            return getAnswersByQuestionId(sq.id?.toString() || '0', courseTermId?.toString() || '0')
              .then((res) => {
                console.log(res)
                const ans: Array<number> = []
                ar.forEach((ant) => {
                  ans.push(res.data.filter((ank: any) => ant == ank.answer).length)
                })

                const sAQ = {id: sq.id || 0, name: sq.name, answers: ans}
                sAQAr.push(sAQ)
              })
              .catch((error) => {
                console.log('Bir hata oluştu:', error)
                // Hata durumunun ele alınması
              })
          })

          return Promise.all(questionPromises)
            .then(() => sAQAr) // sAQAr dizisini döndür
            .catch((error) => {
              console.log('Bir hata oluştu:', error)
              return Promise.resolve([]) // Hata durumunda boş bir dizi döndür
            })
        } else {
          return Promise.resolve([]) // Boş bir promise döndürüyoruz
        }
      })

      console.log(surveyGroupPromises)

      if (surveyGroupPromises) {
        const filteredPromises = surveyGroupPromises.filter(Boolean)
        console.log(surveyGroupPromises)

        Promise.all(filteredPromises)
          .then((results) => {
            const flattenedResults = results.flat() as any[]
            setSurveyReportQuestion(flattenedResults)
            setTotal(i)
          })
          .catch((error) => {
            console.log('Bir hata oluştu:', error)
            // Hata durumunun ele alınması
          })
      }
    }
  }, [courseTermId, survey])

  useEffect(() => {
    if ((survey?.id || 0) > 0) {
      getCommentsBySurveyId(survey?.id, courseTermId).then((res) => {
        console.log(res.data)
        setSurveyReportComment(res.data)
      })
    }
  }, [courseTermId, survey])

  console.log(survey)
  console.log(surveyReportQuestion)

  return (
    <>
      <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-1 mb-16'>
        {survey?.name}
      </h1>
      {survey?.surveyGroup?.map((sg, index) => {
        return (
          <div className={`card mb-7`}>
            <h1
              className='fs-3'
              style={{
                marginLeft: '12px',
                marginTop: '40px',
                marginBottom: '20px',
                color: '#009ef7',
              }}
            >
              {index + 1}. {sg.name}
            </h1>
            <>
              <div className='row'>
                {sg?.surveyQuestion?.map((sq, i) => {
                  return (
                    <>
                      <div className='col-lg-6'>
                        <>
                          <ChartsWidgetSurvey
                            className={sq.id?.toString() || '1'}
                            data={
                              surveyReportQuestion
                                ?.map((a) => {
                                  return a.id == sq.id ? a.answers : undefined
                                })
                                .filter((a) => a != undefined)[0]
                            }
                            categories={answerPoints}
                            title={sq.name || ''}
                          />
                        </>
                      </div>
                    </>
                  )
                })}
              </div>
            </>
          </div>
        )
      })}

      {survey?.hasComment && (
        <div className={`card mb-7 p-5`}>
          <div
            style={{marginLeft: '12px', marginTop: '5px'}}
            className='d-flex align-items-center mb-5'
          >
            <div className='d-flex align-items-center flex-grow-1'>
              <div className='d-flex flex-column'>
                <span className='text-gray-800 fs-6 fw-bold'>Geri Bildirimler</span>
              </div>
            </div>
          </div>
          <div className='p-5 ' style={{maxHeight: '400px', overflowY: 'scroll'}}>
            <>
              {surveyReportComment?.map((res) => {
                return (
                  <div className='d-flex flex-row mb-7 gap-5'>
                    {' '}
                    <span className='bullet bullet-vertical h-25px bg-success'></span>
                    {res.comment}
                  </div>
                )
              })}
            </>
          </div>
        </div>
      )}

      {/* {survey?.surveyGroup?.map((sg, index) => {
        return (
          <>
            <div className={`card mb-7`}>
              <h1
                className='header'
                style={{marginLeft: '12px', marginTop: '12px', color: 'blueviolet'}}
              >
                {index + 1}. {sg.name}
              </h1>

             
              <>
                <div className='row'>
                  {sg?.surveyQuestion?.map((sq, i) => {
                    return (
                      <>
                        
                        <div className='col-lg-6'>
                          <>
                            <ChartsWidgetSurvey
                              className={sq.id?.toString() || '1'}
                              data={
                                surveyReportQuestion
                                  ?.map((a) => {
                                    return a.id == sq.id ? a.answers : undefined
                                  })
                                  .filter((a) => a != undefined)[0]
                              }
                              categories={answerPoints}
                              title={sq.text || ''}
                            />
                          </>
                        </div>
                    
                      </>
                    )
                  })}
                </div>
              </>
            </div>
          </>
        )
      })}
      {survey?.hasComment && (
        <div className={`card mb-7`}>
         
          <div
            style={{marginLeft: '12px', marginTop: '12px'}}
            className='d-flex align-items-center mb-5'
          >
           
            <div className='d-flex align-items-center flex-grow-1'>
             
              <div className='d-flex flex-column'>
                <span className='text-gray-800 fs-6 fw-bold'>Geri Bildirimler</span>
              </div>
              
            </div>
          
          </div>
          <div style={{marginLeft: '28px', marginBottom: '28px'}}>
            <>
              {surveyReportComment?.map((res) => {
                return <div>{res.comment}</div>
              })}
            </>
          </div>
        </div>
      )} */}
    </>
  )
}

export {SurveyReportPage}
