/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import qs from 'qs'
import {Tab} from '../report-tabs/Tab'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {getCourseQuizTotalReport} from '../Requests/_request'
import {CourseQuizBridge} from './ChartBridge'
import {CourseReport} from '../course-report/course'

export function CourseSuccessReport() {
  const intl = useIntl()

  const [newData, setNewData] = useState<any[]>([])

  useEffect(() => {
    getCourseQuizTotalReport().then((res: any) => {
      console.log(res)
      setNewData(res.data)
    })
  }, [])

  console.log(newData)

  return (
    <>
      <PageTitle>{intl.formatMessage({id: 'General_Reports'})}</PageTitle>
      <Tab />
      <CourseReport />
      <div className='card-title m-0'>
        <h3 className='fw-bolder m-0  mb-4 mt-16 p-2'>Başarı Grafikleri</h3>
      </div>
      <div className='row mb-7 '>
        {newData.length &&
          newData?.map((course: any, index: number) => {
            // const average = course?.userQuizReportViewModels?.map((item2: any) => item2.average)
            // const passMark = course?.userQuizReportViewModels?.map((item2: any) => item2.passMark)
            // const quizName = course?.userQuizReportViewModels?.map((item2: any) => item2.quizName)
            const accordionId = `accordion_${index}`
            const headerId = `accordion_header_${index}`
            const bodyId = `accordion_body_${index}`
            return (
              <div className='accordion' id={accordionId} key={index}>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id={headerId}>
                    <button
                      className='accordion-button fs-4 fw-bold collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target={`#${bodyId}`}
                      aria-expanded='false'
                      aria-controls={bodyId}
                    >
                      {course?.totalCourseQuizReportViewModels[0].courseTermName}
                    </button>
                  </h2>
                  <div
                    id={bodyId}
                    className='accordion-collapse collapse'
                    aria-labelledby={headerId}
                    data-bs-parent={`#${accordionId}`}
                  >
                    {course &&
                    course.totalCourseQuizReportViewModels &&
                    course.totalCourseQuizReportViewModels[0]
                      ? (() => {
                          const newData: any = []
                          course.totalCourseQuizReportViewModels.map((quiz: any) => {
                            const foundCourse = newData.find(
                              (item: any) => item?.courseId === quiz?.courseId
                            )
                            if (foundCourse) {
                              foundCourse.quizs.push(quiz)
                            } else {
                              const newCourse = {
                                courseId: quiz.courseId,
                                courseName: quiz.courseName,
                                quizs: [quiz],
                              }
                              newData.push(newCourse)
                            }
                          })

                          console.log(newData)

                          const veri = newData?.map((item: any, index2: any) => {
                            const percentage = item?.quizs.map((item2: any) => item2.percentage)
                            const quizName = item?.quizs.map((item2: any) => item2.quiz)
                            const courseName = item.courseName

                            return (
                              <CourseQuizBridge
                                className={''}
                                percentage={percentage}
                                quizName={quizName}
                                courseName={courseName}
                              />
                            )
                          })
                          return veri
                        })()
                      : null}
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </>
  )
}
