/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
// import {Dropdown1} from '../dropdown/Dropdown1'

type Props = {
  //   image: string
  //   title: string
  //   description: string
  //   status: 'up' | 'down'
  //   statusValue: number
  //   statusDesc: string
  //   progress: number
  //   progressType: string
  title: any
  user: any
  image: any
}

const InfoCard: FC<Props> = ({
  //   image,
  //   title,
  //   description,
  //   status,
  //   statusValue,
  //   statusDesc,
  //   progress,
  //   progressType,
  user,
  title,
  image,
}) => {
  return (
    <div className='col-md-3 col-12 mt-3 mt-md-0 px-md-7'>
      <div className='card h-100    '>
        <div className='card-header flex-nowrap border-0 pt-9'>
          <div className='card-title m-0'>
            <div className='symbol symbol-65px w-65px bg-light me-5'>
              <img src={toAbsoluteUrl(image)} alt='Metronic' className='' />
            </div>

            <a href='#' className='fs-4 fw-bold text-hover-primary text-gray-600 m-0'>
              {title}
            </a>
          </div>

          {/* <div className='card-toolbar m-0'>
          <button
            type='button'
            className='btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary me-n3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-3 text-primary' />
          </button>

           <Dropdown1 /> 
        </div> */}
        </div>

        <div className='card-body d-flex flex-column px-12 pt-6 pb-8 '>
          <div className='fs-2tx fw-bolder mb-3'>{user}</div>
        </div>
      </div>
    </div>
  )
}

export {InfoCard}
