import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/Auth/get-user-by-token`
export const GET_TOKEN_URL = `${API_URL}/Auth/get-token`
export const LOGIN_URL = `${API_URL}/Auth/login`
export const REGISTER_URL = `${API_URL}/Auth/register`
export const SELFREGISTER_URL = `${API_URL}/Auth/selfregister`
export const REQUEST_PASSWORD_URL = `${API_URL}/Auth/forgot_password`

// Server should return AuthModel
export function login(username: string, password: string, data: any, fbid: any, device: any) {
  return axios.post<AuthModel>(GET_TOKEN_URL, {
    username,
    password,
    data,
    fbid,
    device,
  })
}
// data
// fbid
//  device

// Server should return AuthModel
export function register(
  firstname: string,
  lastname: string,
  email: string,
  identity: string,
  password: string,
  role: string,
  tenantId: number,
  genderId: number,
  positionId: number,
  organizationId: number,
  phone: string,

  // password_confirmation: string
) {
  return axios.put(SELFREGISTER_URL, {
    email:email,
    name: firstname + " " + lastname,
    password: password,
    identity: identity,
    
    role: role,
    tenantId: tenantId,
    genderId: genderId,
    positionId: positionId,
    organizationId: organizationId,
    phone: phone,
    // password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken() {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}
