import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Model, QueryResponse} from './_models'
import qs from 'qs'
const API_URL = process.env.REACT_APP_API_URL
const PROP_URL = `${API_URL}/Topic`
const PROP_URL2 = `${API_URL}/Custom/getTopicLesson`
const GET_LESSON_URL = `${API_URL}/Custom/getLessonUrl`
const SET_LESSON_POSITION_URL = `${API_URL}/Custom/setLessonPosition`
const SET_LESSON_FINISH_URL = `${API_URL}/Custom/setLessonFinish`
const GET_LESSON_POSITION_URL = `${API_URL}/Custom/getLessonPosition`
const GET_LESSON_Type_URL = `${API_URL}/Custom/getLessonType`

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
const getTopic = (query: string, id?: any, modelstoinclude?: string): Promise<QueryResponse> => {
  const qis = query.split('&')
  let newq: Array<string> = []
  qis.map((q) => {
    if (q.startsWith('filter') && q.slice(-1) != '=') {
      if (q.includes('_id')) {
        const aq = capitalizeFirstLetter(
          q.replace('_id', 'Id').replace('filter_', '').replace('=', '*')
        )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('strt') && !q.includes(' ')) {
        const aq =
          '(Date)' +
          capitalizeFirstLetter(
            q.replace('strt', '').replace('filter_', '').replaceAll('-', '.').replace('=', '>')
          )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('ndnd') && !q.includes(' ')) {
        const aq =
          '(Date)' +
          capitalizeFirstLetter(
            q.replace('ndnd', '').replace('filter_', '').replaceAll('-', '.').replace('=', '<')
          )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('String')) {
        const aq = capitalizeFirstLetter(
          q.replace('String', '').replace('filter_', '').replace('=', '_')
        )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('stNMB')) {
        const aq = capitalizeFirstLetter(
          q.replace('stNMB', '').replace('filter_', '').replace('=', '>')
        )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('enNMB')) {
        const aq = capitalizeFirstLetter(
          q.replace('enNMB', '').replace('filter_', '').replace('=', '<')
        )
        //console.log(aq)
        newq.push(aq)
      } else if (q.includes('BL')) {
        const aq = capitalizeFirstLetter(
          q.replace('BL', '').replace('filter_', '').replace('=', '*')
        )
        //console.log(aq)
        newq.push(aq)
      }
    }
  })
  const newqstring = newq.filter((f) => f).join('|')

  let mti = ''
  if (modelstoinclude != undefined && modelstoinclude != '') {
    mti = '&modelstoinclude=' + modelstoinclude
  }
  let sti = ''
  // if (select != undefined && select != '') {
  //   sti = '&select=' + select
  // }

  return axios
    .get(`${PROP_URL2}?courseid=${id}&${query}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const customGetTopic = async (id?: any): Promise<any> => {
  return await axios.get(`${PROP_URL2}?courseid=${id}`).then((res: AxiosResponse) => {
    return res.data
  })
}

const listTopic = async (select?: string, modelstoinclude?: string): Promise<any> => {
  let mti = ''
  if (modelstoinclude != undefined) {
    mti = '&modelstoinclude=' + modelstoinclude
  }
  return await axios
    .get(`${PROP_URL}/getAll?page=1&items_per_page=99999${mti}&select=${select}`)
    .then((res: AxiosResponse) => {
      return res.data
    })
}

const getTopicById = (id: ID): Promise<Model | undefined> => {
  return axios
    .get(`${PROP_URL}/${id}`)
    .then((response: AxiosResponse<Response<Model>>) => response.data)
    .then((response: Response<Model>) => response as any)
}

const createTopic = (prop: Model): Promise<Model | undefined> => {
  const qsd = qs.parse(window.location.search, {ignoreQueryPrefix: true}).courseId ?? ''
  prop.courseId = parseInt(qsd?.toString())
  return axios
    .put(PROP_URL, prop)
    .then((response: AxiosResponse<Response<Model>>) => response.data)
    .then((response: Response<Model>) => response.data)
}

const updateTopic = (prop: Model): Promise<Model | undefined> => {
  return axios
    .post(`${PROP_URL}/${prop.id}`, prop)
    .then((response: AxiosResponse<Response<Model>>) => response.data)
    .then((response: Response<Model>) => response.data)
}

const deleteTopic = (propId: ID): Promise<void> => {
  return axios.delete(`${PROP_URL}/${propId}`).then(() => {})
}

const deleteSelectedTopic = (propIds: Array<ID>): Promise<void> => {
  const requests = propIds.map((id) => axios.delete(`${PROP_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const getLessonUrl = async (lid: number): Promise<any> =>
  await axios.get(`${GET_LESSON_URL}?lid=${lid}`).then((res: AxiosResponse) => {
    return res.data
  })
const getLessonPosition = async (lid: number): Promise<any> =>
  await axios.get(`${GET_LESSON_POSITION_URL}?lid=${lid}`).then((res: AxiosResponse) => {
    return res.data
  })
const getLessonType = async (lid: number): Promise<any> =>
  await axios.get(`${GET_LESSON_Type_URL}?lid=${lid}`).then((res: AxiosResponse) => {
    return res.data
  })
const setLessonPosition = async (lid: number, position: number): Promise<any> =>
  await axios
    .get(`${SET_LESSON_POSITION_URL}?lid=${lid}&position=${position}`)
    .then((res: AxiosResponse) => {
      return 1
    })

const setLessonFinish = async (lid: number): Promise<any> =>
  await axios.get(`${SET_LESSON_FINISH_URL}?lid=${lid}`).then((res: AxiosResponse) => {
    return 1
  })

export {
  getTopic,
  deleteTopic,
  deleteSelectedTopic,
  getTopicById,
  createTopic,
  updateTopic,
  listTopic,
  customGetTopic,
  getLessonUrl,
  setLessonPosition,
  getLessonPosition,
  getLessonType,
  setLessonFinish,
}
