import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {getTestPercentage} from '../_requests'
import qs from 'qs'
import {ChartsWidgetGeneral} from '../../../../_metronic/partials/widgets/charts/ChartsWidgetGeneral'

const TestPercentage: FC = () => {
  const intl = useIntl()
  const [data, setData] = useState<any>(undefined)

  useEffect(() => {
    const qsd = qs.parse(window.location.search, {ignoreQueryPrefix: true}).userId ?? undefined
    getTestPercentage(qsd).then((res: any) => {
      console.log(res)
      const newdata = [
        {value: res.finishedCount, label: 'Biten Test'},
        {value: res.inProgressCount, label: 'Devam Eden Test'},
        {
          value: res.totalTestCount - (res.inProgressCount + res.finishedCount),
          label: 'Başlanmayan Test',
        },
      ]

      setData(newdata)
    })
  }, [])

  return (
    <div>
      <div className='card card-xl-stretch '>
        <div className='card-body pt-3 '>
          <div className='d-flex align-items-sm-center '>
            <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
              <div className='flex-grow-1 me-2'>
                <span className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                  Biten Test Sayısı <span
              className='badge  fw-bold my-2'
              style={{backgroundColor: '#67b7dc', color: '#fff'}}
            >
              {data?.length >= 2 && data[0]?.value}
            </span>
                </span>
              </div>
              <div className='flex-grow-1 me-2'>
                <span className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                  Devam Eden Test Sayısı <span
              className='badge  fw-bold my-2'
              style={{backgroundColor: '#a367dc', color: '#fff'}}
            >
              {data?.length >= 2 && data[1]?.value}
            </span>
                </span>
              </div>
              <div className='flex-grow-1 me-2'>
                <span className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                  Henüz Başlanmayan Test Sayısı <span
              className='badge fw-bold my-2'
              style={{backgroundColor: '#dc67ce', color: '#fff'}}
            >
              {data?.length >= 2 && data[2]?.value}
            </span>
                </span>
              </div>
            </div>
            
          </div>
          
          
          
        </div>
      </div>
    </div>
  )
}

export {TestPercentage}
