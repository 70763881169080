import React, {useState, useEffect} from 'react'
import {ChartQuiz} from '../../../../_metronic/partials/widgets/charts/ChartsQuiz'
import {getCourseTermById} from '../../courseterm/list/core/_requests'
import {ChartSurvey} from '../../../../_metronic/partials/widgets/charts/ChartsSurvey'

type Props = {
  className: string
  courseName: any
  answer: any
  surveyName: any
  sQuestion: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const SurveyChartsBridge = ({className, courseName, answer, surveyName, sQuestion}: Props) => {
  return (
    <div className='col-md-6 col-12'>
      <ChartSurvey
        className=''
        courseName={courseName}
        answer={answer}
        surveyName={surveyName}
        sQuestion={sQuestion}
      />
    </div>
  )
}

export {SurveyChartsBridge}
