import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {Tab} from '../Tabs/Tab'
import {getQuizAnswers} from '../_requests'

import qs from 'qs'

const ViewAnswers: FC = () => {
  const [data, setData] = useState<any>(undefined)

  const intl = useIntl()

  useEffect(() => {
    const qsd = qs.parse(window.location.search, {ignoreQueryPrefix: true}).userId
    const qsd2 = qs.parse(window.location.search, {ignoreQueryPrefix: true}).quizId
    getQuizAnswers(qsd, qsd2).then((res: any) => {
      setData(res.data)
    })
  }, [])

  console.log(data)

  return (
    <>
      <PageTitle>{intl.formatMessage({id: 'USERCOURSEREPORTS'})}</PageTitle>

      <Tab />

      <div className={`card `}>
        <div className='card-header border-0'>
          <h3 className='card-title fw-bold text-dark'>Cevaplar</h3>
          <div className='card-toolbar'></div>
        </div>

        <div className='card-body pt-2 ' style={{paddingRight: '50px'}}>
          <div className='d-flex align-items-center mb-8 row'>
            <div className='col-7 d-flex flex-row align-items-center'>
              <span className='bullet bullet-vertical h-40px bg-warning'></span>
              <div className='form-check form-check-custom form-check-solid mx-5'>
                <input className='form-check-input' type='checkbox' value='' disabled={true} />
              </div>

              <div className='flex-grow-1'>
                <a className='text-gray-800 text-hover-primary fw-bold fs-6'>Metin</a>
              </div>
            </div>

            <div className='col-3 align-items-center'>
              <div className='flex-grow-1'>
                <a className='text-gray-800 text-hover-primary fw-bold fs-6'>Cevap</a>
              </div>
            </div>
            <div className='col-2 align-items-center'>
              <a className='text-gray-800 text-hover-primary fw-bold fs-6'>Durum</a>
            </div>
          </div>
          {data?.map((item: any) => {
            return (
              <div className='d-flex align-items-center mb-8 row'>
                <div className='col-7 d-flex flex-row align-items-center'>
                  <span
                    className={`bullet bullet-vertical h-40px ${
                      item?.isTrue == 'Doğru' ? 'bg-success' : 'bg-danger'
                    } `}
                  ></span>

                  <div className='form-check form-check-custom form-check-solid mx-5'>
                    <input className='form-check-input' type='checkbox' value='' disabled={true} />
                  </div>

                  <div className='flex-grow-1'>
                    <a className='text-gray-800 text-hover-primary fw-bold fs-6'>
                      {item?.questionText}
                    </a>
                  </div>
                </div>
                <div className='col-3 align-items-center'>
                  <div className='flex-grow-1'>
                    <a className='text-gray-800 text-hover-primary fw-bold fs-6'>{item?.answer}</a>
                  </div>
                </div>
                <div className='col-2 align-items-center'>
                  <span
                    className={`badge  ${
                      item?.isTrue == 'Doğru' ? 'badge-light-success' : 'badge-light-danger'
                    }  fs-8 fw-bold`}
                  >
                    {item?.isTrue}
                  </span>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export {ViewAnswers}
