/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useIntl} from 'react-intl'
import moment from 'moment'

type Props = {
  className: string
  items: any
}

const ListsWidgetUser: React.FC<Props> = ({className, items}) => {
  console.log(items)
  const intl = useIntl()
  return (
    <div className='card card-xl-stretch mb-xl-8'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3
          className='card-title align-items-start flex-column'
          style={{borderBottom: '1px solid #50adf7 ', width: '100%'}}
        >
          <span className='card-label fw-bold text-dark'>{items?.course && items?.course}</span>

          {/* <span className='text-muted mt-1 fw-semibold fs-7'>
            {items.completionTime != undefined
              ? intl.formatMessage({id: 'SPENTTIME'}) +
                ': ' +
                Math.floor(items.completionTime / 60 / 60) +
                ' ' +
                intl.formatMessage({id: 'HOURS'}) +
                ', ' +
                (Math.floor(items.completionTime / 60) % 60) +
                ' ' +
                intl.formatMessage({id: 'MINUTES'}) +
                ', ' +
                (items.completionTime % 60) +
                ' ' +
                intl.formatMessage({id: 'SECONDS'})
              : ''}
          </span> */}
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-3 '>
        {/* begin::Items */}
        {items?.topics?.map((res: any) => {
          return (
            <div className='d-flex align-items-sm-center '>
              <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                <div className='flex-grow-1 me-2'>
                  <span className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                    {res?.topic}
                  </span>
                </div>
              </div>
              <span className='badge badge-success fw-bold my-2'>
                Başlangıç :{moment(res?.startTime).format('YYYY-MM-DD HH:mm')}
              </span>

              <span className='badge badge-danger fw-bold m-2'>
                Bitiş :{moment(res?.endTime).format('YYYY-MM-DD HH:mm')}
              </span>
            </div>
          )
        })}

        {/* end::Items */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidgetUser}
