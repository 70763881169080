/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import qs from 'qs'
import {Tab} from '../report-tabs/Tab'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {getQuizReport} from '../Requests/_request'
import {GeneralQuizCharts} from '../../../../_metronic/partials/widgets/charts/GeneralQuizChart'
import {TotalQuizReport} from './TotalQuiz'
import {QuizBridge} from './quizBridge'

export function QuizReport() {
  const intl = useIntl()

  const [newData, setNewData] = useState<any[]>([])

  useEffect(() => {
    getQuizReport().then((res: any) => {
      console.log(res.data)
      const groupedData: any[] = []
      res?.data.forEach((item: any) => {
        const courseTermId = item.courseTermId
        const existingData = groupedData.find((data) => data.courseTermId === courseTermId)
        console.log(existingData)
        if (existingData) {
          existingData.items.push(item)
        } else {
          const newDataItem = {courseTermId, items: [item]}
          groupedData.push(newDataItem)
        }
      })
      setNewData(groupedData)
    })
  }, [])
  console.log(newData)
  return (
    <>
      <PageTitle>{intl.formatMessage({id: 'General_Reports'})}</PageTitle>
      <Tab />

      {newData.length > 0 &&
        newData.map((term: any, index: number) => {
          const courseTermName = term?.items[0]?.courseTerm
          const finished = term?.items?.map((item2: any) => item2.finished)
          const inProgress = term?.items?.map((item2: any) => item2.inProgress)
          const notStarted = term?.items?.map((item2: any) => item2.notStarted)
          const total = term?.items?.map((item2: any) => item2.total)
          const name = term?.items?.map((item2: any) => item2.name)
          const accordionId = `accordion_${index}`
          const headerId = `accordion_header_${index}`
          const bodyId = `accordion_body_${index}`

          return (
            <div className='accordion' id={accordionId} key={index}>
              <div className='accordion-item'>
                <h2 className='accordion-header' id={headerId}>
                  <button
                    className='accordion-button fs-4 fw-bold collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target={`#${bodyId}`}
                    aria-expanded='false'
                    aria-controls={bodyId}
                  >
                    {courseTermName}
                  </button>
                </h2>
                <div
                  id={bodyId}
                  className='accordion-collapse collapse'
                  aria-labelledby={headerId}
                  data-bs-parent={`#${accordionId}`}
                >
                  <div className='accordion-body'>
                    <QuizBridge
                      className=''
                      finished={finished}
                      inProgress={inProgress}
                      notStarted={notStarted}
                      total={total}
                      name={name}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        })}
    </>
  )
}
