/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import qs from 'qs'
import {TabHr} from '../report-tabs/Tab'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {getTestPercentageReport, getTestPercentageReport2} from '../Requests/_request'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import {listJourney} from '../../journey/list/core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
am4core.useTheme(am4themes_animated)

export const JourneyReport: FC = () => {
  const intl = useIntl()
  const [newData, setNewData] = useState<any[]>([])
  const [journey, setJourney] = useState<any[]>([])

  useEffect(() => {
    getTestPercentageReport2().then((res: any) => {
      console.log(res)
      let x = res?.data?.map((item: any) => ({
        category: item.inventoryTestName,
        value1: item.notStarted,
        value2: item.inProgress,
        value3: item.finished,
      }))
      setNewData(x)
    })

    listJourney('Id,Name').then((res: any) => {
      setJourney(res.data)
    })

    // return () => {
    //   if (chart) {
    //     chart.dispose()
    //   }
    // }
  }, [])

  const getChart = (id: any) => {
    getTestPercentageReport(id).then((res: any) => {
      console.log(res)
      let x = res?.data?.map((item: any) => ({
        category: item.inventoryTestName,
        value1: item.notStarted,
        value2: item.inProgress,
        value3: item.finished,
      }))
      setNewData(x)
    })
  }

  console.log(newData)

  useEffect(() => {
    let chart: am4charts.XYChart | null = null

    if (newData.length > 0) {
      let chart: any = am4core.create('chartdiv997', am4charts.XYChart)
      chart.hiddenState.properties.opacity = 0

      // chart.data = chart.data = [
      //   {
      //     category: 'One',
      //     value1: 1,
      //     value2: 5,
      //     value3: 3,
      //   },
      //   {
      //     category: 'Two',
      //     value1: 2,
      //     value2: 5,
      //     value3: 3,
      //   },
      //   {
      //     category: 'Three',
      //     value1: 3,
      //     value2: 5,
      //     value3: 4,
      //   },
      //   {
      //     category: 'Four',
      //     value1: 4,
      //     value2: 5,
      //     value3: 6,
      //   },
      //   {
      //     category: 'Five',
      //     value1: 3,
      //     value2: 5,
      //     value3: 4,
      //   },
      //   {
      //     category: 'Six',
      //     value1: 2,
      //     value2: 13,
      //     value3: 1,
      //   },
      // ]
      chart.data = newData

      chart.colors.step = 2
      chart.padding(30, 30, 10, 30)
      chart.legend = new am4charts.Legend()

      chart.colors.step = 2
      chart.padding(30, 30, 10, 30)
      chart.legend = new am4charts.Legend()

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      categoryAxis.dataFields.category = 'category'
      categoryAxis.renderer.grid.template.location = 0

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
      valueAxis.min = 0
      valueAxis.max = 100
      valueAxis.strictMinMax = true
      valueAxis.calculateTotals = true
      valueAxis.renderer.minWidth = 50

      // ... Rest of the chart configuration

      let series1 = chart.series.push(new am4charts.ColumnSeries())
      series1.columns.template.width = am4core.percent(80)
      series1.columns.template.tooltipText = '{name}: {valueY.totalPercent.formatNumber("#.00")}%'
      series1.name = 'Henüz Başlamadı'
      series1.dataFields.categoryX = 'category'
      series1.dataFields.valueY = 'value1'
      series1.dataFields.valueYShow = 'totalPercent'
      series1.dataItems.template.locations.categoryX = 0.5
      series1.stacked = true
      series1.tooltip.pointerOrientation = 'vertical'

      let bullet1 = series1.bullets.push(new am4charts.LabelBullet())
      bullet1.interactionsEnabled = false
      bullet1.label.text = "{valueY.totalPercent.formatNumber('#.00')}%"
      bullet1.label.fill = am4core.color('#ffffff')
      bullet1.locationY = 0.5

      let series2 = chart.series.push(new am4charts.ColumnSeries())
      series2.columns.template.width = am4core.percent(80)
      series2.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}%"
      series2.name = 'Devam Ediyor'
      series2.dataFields.categoryX = 'category'
      series2.dataFields.valueY = 'value2'
      series2.dataFields.valueYShow = 'totalPercent'
      series2.dataItems.template.locations.categoryX = 0.5
      series2.stacked = true
      series2.tooltip.pointerOrientation = 'vertical'

      let bullet2 = series2.bullets.push(new am4charts.LabelBullet())
      bullet2.interactionsEnabled = false
      bullet2.label.text = "{valueY.totalPercent.formatNumber('#.00')}%"
      bullet2.locationY = 0.5
      bullet2.label.fill = am4core.color('#ffffff')

      let series3 = chart.series.push(new am4charts.ColumnSeries())
      series3.columns.template.width = am4core.percent(80)
      series3.columns.template.tooltipText = "{name}: {valueY.totalPercent.formatNumber('#.00')}%"
      series3.name = 'Tamamladı'
      series3.dataFields.categoryX = 'category'
      series3.dataFields.valueY = 'value3'
      series3.dataFields.valueYShow = 'totalPercent'
      series3.dataItems.template.locations.categoryX = 0.5
      series3.stacked = true
      series3.tooltip.pointerOrientation = 'vertical'

      let bullet3 = series3.bullets.push(new am4charts.LabelBullet())
      bullet3.interactionsEnabled = false
      bullet3.label.text = "{valueY.totalPercent.formatNumber('#.00')}%"
      bullet3.locationY = 0.5
      bullet3.label.fill = am4core.color('#ffffff')

      chart.scrollbarX = new am4core.Scrollbar()
    }

    return () => {
      if (chart) {
        chart.dispose()
      }
    }
  }, [newData])

  return (
    <div
      className={`card bgi-no-repeat card-xl-stretch mb-xl-8 p-10`}
      style={{
        backgroundPosition: 'right top',
        backgroundSize: '30% auto',
        backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/abstract-4.svg')})`,
      }}
    >
      <PageTitle>{intl.formatMessage({id: 'MENU.HRREPORT'})}</PageTitle>
      {/* <TabHr /> */}

      <div className=' row d-flex flex-column px-8  '>
        <label className=' col-md-4 col-12   fw-bold fs-3 pb-8 px-16 '>
          Yolculuk Katılım Bilgileri
        </label>
        <div className='col-md-6  col-12 px-16  '>
          <label className='required fw-bold fs-6 mb-2 '>Yolculuk Seçiniz</label>
          <select
            className='form-select form-select-solid form-select-md  '
            onChange={(e: any) => {
              getChart(e.target.value)
            }}
            // value={formik.values.inventoryTypeId}
          >
            <option value=''>{intl.formatMessage({id: 'SEÇİNİZ'})}</option>
            {journey?.map((myval: any) => (
              <option value={myval?.id} key={myval?.id as any}>
                {myval?.name as any}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div id='chartdiv997' style={{width: '100%', height: '500px'}}></div>
    </div>
  )
}
