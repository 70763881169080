import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import DailyNotification from './DailyQuestions'
import SingleNotification from './SingleNotification'
import MultiNotification from './MutliNotification'
import {useAuth} from '../../modules/auth'
import {useIntl} from 'react-intl'

const Notification = () => {
  const {currentUser} = useAuth()

  return (
    <>
      <PageTitle>Bildirimler</PageTitle>

      {currentUser?.roleName == 'CODE35' ? (
        <>
          <DailyNotification />
        </>
      ) : null}

      <SingleNotification />

      {currentUser?.roleName == 'CODE35' ? (
        <>
          <MultiNotification />
        </>
      ) : null}
    </>
  )
}

export default Notification
