import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import qs from 'qs'
import {getStressReport} from '../_requests'
import {StressBridge} from './StessBridge'

const StressReport: FC = () => {
  const intl = useIntl()
  const [data, setData] = useState<any>(undefined)

  useEffect(() => {
    const qsd = qs.parse(window.location.search, {ignoreQueryPrefix: true}).userId ?? undefined
    getStressReport(qsd).then((res: any) => {
      setData(res.data)
    })
  }, [])

  console.log(data)
  return (
    <>
      <div className=' mb-5 mb-xl-10'>
        <div className='mt-7 p-12'>
          {data?.length > 0 && (
            <>
              {(() => {
                const maxScore = data?.map((item2: any) => item2.maxScore)
                const hrDimensionName = data?.map((item2: any) => item2.hrDimensionName)
                const userScore = data?.map((item2: any) => item2.userScore)

                return (
                  <StressBridge
                    maxScore={maxScore}
                    hrDimensionName={hrDimensionName}
                    userScore={userScore}
                  />
                )
              })()}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export {StressReport}
