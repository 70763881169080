/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import * as am4core from '@amcharts/amcharts4/core'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import {JourneyReport} from './journey-report'
import {DimensionReport} from './dimension-report'
import {GenderReport} from './gender-report'
import DailyReport from './daily-report'

am4core.useTheme(am4themes_animated)

export const GeneralHRReport: FC = () => {
  const intl = useIntl()

  return (
    <>
      <JourneyReport />
      <DimensionReport />
      <GenderReport />
      <DailyReport />
    </>
  )
}

// ....
