/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState, useEffect} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {register} from '../core/_requests'

import {listCustomCode as listGender} from '../../../pages/code/list/core/_requests'


import Swal from 'sweetalert2'



const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'En az 3 Karakter')
    .max(50, 'En Fazla 50 Karakter')
    .required('İsim Gerekli'),
  email: Yup.string()
    .email('E-posta formatı uyumsuz')
    .min(3, 'En az 3 Karakter')
    .max(50, 'En Fazla 50 Karakter')
    .required('E-Posta gerekli'),
  lastname: Yup.string()
    .min(3, 'En az 3 Karakter')
    .max(50, 'En Fazla 50 Karakter')
    .required('Soyad Gerekli'),
  password: Yup.string()
    .min(3, 'En az 8 Karakter')
    .max(50, 'En Fazla 50 Karakter')
    .required('Parola gerekli'),
    identity: Yup.string()
    .min(11, 'En az 8 Karakter')
    .max(11, 'En Fazla 50 Karakter')
    .required('Tc Kimlik gerekli'),
  // changepassword: Yup.string()
  //   .required('Password confirmation is required')
  //   .when('password', {
  //     is: (val: string) => (val && val.length > 0 ? true : false),
  //     then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
  //   }),
  // acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const [gender, setGender] = React.useState([])
  const navigate = useNavigate();

  useEffect(() => {
    
    listGender().then((res: any) => {
      let filteredData = res?.filter((item: any) => item.type == 'Gender')
      setGender(filteredData)
    })
  }, [])

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      identity: '',
      role: 'PARTICIPANT',
      tenantId: 2,
      genderId: 0,
      positionId: 1,
      organizationId: 1,
      phone: '',
      // changepassword: '',
      // acceptTerms: false,
    },
    validationSchema: registrationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        // Kayıt işlemi için gerekli API çağrısı
        await register(
          values.firstname,
          values.lastname,
          values.email,
          values.identity,
          values.password,
          values.role,
          values.tenantId,
          values.genderId,
          values.positionId,
          values.organizationId,
          values.phone,
        );
        // Başarılı kayıt sonrası işlemler
        navigate('/auth/login'); // Kullanıcıyı login sayfasına yönlendir

      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>Yeni Bir Hesap Yarat</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          <Link to='/auth/login' className='link-primary fw-bolder' style={{marginLeft: '5px'}}>
            Zaten Bir Hesabım Var
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* end::Heading */}

      {/* begin::Action */}
      {/* <button type='button' className='btn btn-light-primary fw-bolder w-100 mb-10'>
        <img
          alt='Logo'
          src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
          className='h-20px me-3'
        />
        Sign in with Google
      </button> */}
      {/* end::Action */}

      <div className='d-flex align-items-center mb-10'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span className='fw-bold text-gray-400 fs-7 mx-2'>-</span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='row fv-row mb-7'>
      <div className='col-xl-6'>
        <label className='form-label fw-bolder text-dark fs-6'>Ad</label>
            <input
              placeholder='Ad'
              {...formik.getFieldProps('firstname')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.firstname && formik.errors.firstname},
                {
                  'is-valid': formik.touched.firstname && !formik.errors.firstname,
                }
              )}
              type='text'
              name='firstname'
              autoComplete='off'
            />
            {formik.touched.firstname && formik.errors.firstname && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.firstname}</span>
              </div>
            )}
        </div>
        <div className='col-xl-6'>
        <label className='form-label fw-bolder text-dark fs-6'>Soyad</label>
            <input
              placeholder='Soyad'
              {...formik.getFieldProps('lastname')}
              className={clsx(
                'form-control bg-transparent',
                {'is-invalid': formik.touched.lastname && formik.errors.lastname},
                {
                  'is-valid': formik.touched.lastname && !formik.errors.lastname,
                }
              )}
              type='text'
              name='lastname'
              autoComplete='off'
            />
            {formik.touched.lastname && formik.errors.lastname && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.lastname}</span>
              </div>
            )}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>E-Posta</label>
        <input
          placeholder='E-posta'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='text'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Telefon</label>
        <input
          placeholder='Telefon'
          {...formik.getFieldProps('phone')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.phone && formik.errors.phone},
            {
              'is-valid': formik.touched.phone && !formik.errors.phone,
            }
          )}
          type='text'
          name='phone'
          autoComplete='off'
        />
        {formik.touched.phone && formik.errors.phone && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.phone}</span>
          </div>
        )}
      </div>
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>Tc Kimlik No</label>
        <input
          placeholder='Tc Kimlik'
          {...formik.getFieldProps('identity')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.identity && formik.errors.identity},
            {
              'is-valid': formik.touched.identity && !formik.errors.identity,
            }
          )}
          type='text'
          name='identity'
          autoComplete='off'
        />
        {formik.touched.identity && formik.errors.identity && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.identity}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='mb-10 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Şifre</label>
          <div className='position-relative mb-3'>
          <input
          placeholder='Şifre'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.password && formik.errors.password},
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
          type='password'
          name='password'
          autoComplete='off'
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.password}</span>
          </div>
        )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            {/* <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div> */}
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>Şifreniz en az 8 karakter içermelidir.</div>
      </div>
      <div className='row d-flex flex-row'>
            <div className='fv-row mb-7 col-12 '>
              <label className='required fw-bold fs-6 mb-2'>Cinsiyet</label>
              <select
                className='form-select form-select-solid form-select-md'
                {...formik.getFieldProps('genderId')}
                value={formik.values.genderId}
              >
                <option value=''>SEÇİNİZ</option>
                {gender.map((myval: any) => (
                  <option value={myval?.id} key={myval?.id as any}>
                    {(myval?.name as any) === 'Female'
                      ? 'Kadın'
                      : (myval?.name as any) === 'Male'
                      ? 'Erkek'
                      : 'Diğer'}
                  </option>
                ))}
              </select>
            </div>
            
          </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      {/* <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
        <input
          type='password'
          placeholder='Password confirmation'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div> */}
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            <a href='https://silamasalokulu.com/uyelik_sozlesmesi.pdf' target='_blank' className='ms-1 link-primary'>
              Kullanım Koşullarını
            </a>

            Kabul Ediyorum .
          </label>
          {/* {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )} */}
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Kaydet</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Geri
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
