/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useLocation} from 'react-router-dom'

import moment from 'moment'

const Tab: React.FC = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const cid = searchParams.get('userId')
  console.log(cid)

  const [projectInfo, setProjectInfo] = React.useState<any>([])

  useEffect(() => {
    // getTermById(cid).then((res: any) => {
    //   console.log(res)
    //   setProjectInfo(res || [])
    // })
  }, [])

  // const newProjectInfo = projectInfo.filter((item) => item.id == cid)
  console.log(projectInfo)

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex overflow-auto h-55px flex-md-row flex-md-column'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/approvedServicePrice' && 'active')
                }
                to={`/reports`}
              >
                Sınav Durum Raporları
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/approvedServicePrice' && 'active')
                }
                to={`/quiz-reports`}
              >
                Genel Sınav Başarı Raporları
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/approvedServicePrice' && 'active')
                }
                to={`/course-success`}
              >
                Kurs Sınav Başarı Raporları
              </Link>
            </li>
            {/* <li className='nav-item'>
            <Link
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === '/approvedServicePrice' && 'active')
              }
              to={`/course-reports`}
            >
              Kurslar
            </Link>
          </li> */}
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/approvedServicePrice' && 'active')
                }
                to={`/topic-reports`}
              >
                Konu Başarı Raporları
              </Link>
            </li>
            {/* <li className='nav-item'>
            <Link
              className={
                `nav-link text-active-primary me-6 ` +
                (location.pathname === '/approvedServicePrice' && 'active')
              }
              to={`/general-hr-report`}
            >
              HR Raporları
            </Link>
          </li> */}
          </ul>
        </div>
      </div>
    </div>
  )
}

export {Tab}
