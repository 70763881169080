/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import qs from 'qs'
import {TabHr} from '../report-tabs/Tab'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {getTestDimensionReport, getTestDimensionReport2} from '../Requests/_request'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import {getGenderReport} from '../Requests/_request'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

am4core.useTheme(am4themes_animated)

export const GenderReport: FC = () => {
  const intl = useIntl()
  const [newData, setNewData] = useState<any[]>([])

  useEffect(() => {
    getGenderReport().then((res: any) => {
      console.log(res)

      const femaleData = res?.femaleStressReports.map((item: any) => ({
        age: item.hrDimensionName,
        female: item.percentage,
      }))

      const maleDataPromise = res?.maleStressReports.map((item: any) => ({
        male: -1 * item.percentage,
      }))

      Promise.all(maleDataPromise).then((maleData: any) => {
        const combinedData = femaleData.map((item: any, index: any) => ({
          ...item,
          ...maleData[index],
        }))

        setNewData(combinedData)
      })
    })
  }, [])

  console.log(newData)

  useEffect(() => {
    let chart: am4charts.XYChart | null = null

    if (newData.length > 0) {
      let chart: any = am4core.create('chartdiv938', am4charts.XYChart)

      //   chart.data = [
      //     {
      //       age: '5-9',
      //       female: 4.1,
      //       male: -4.4,
      //     },
      //     {
      //       age: '0-4',
      //       female: 4.8,
      //       male: -5.0,
      //     },
      //   ]
      console.log(newData)
      chart.data = newData
      // Use only absolute numbers
      chart.numberFormatter.numberFormat = '#.#s'

      // Create axes
      let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis())
      categoryAxis.dataFields.category = 'age'
      categoryAxis.renderer.grid.template.location = 0
      categoryAxis.renderer.inversed = true

      let valueAxis = chart.xAxes.push(new am4charts.ValueAxis())
      valueAxis.extraMin = 0.1
      valueAxis.extraMax = 0.1
      valueAxis.renderer.minGridDistance = 40
      valueAxis.renderer.ticks.template.length = 5
      valueAxis.renderer.ticks.template.disabled = false
      valueAxis.renderer.ticks.template.strokeOpacity = 0.4
      valueAxis.renderer.labels.template.adapter.add('text', function (text: any) {
        return text == 'Male' || text == 'Female' ? text : text + '%'
      })

      // Create series
      let male = chart.series.push(new am4charts.ColumnSeries())
      male.dataFields.valueX = 'male'
      male.dataFields.categoryY = 'age'
      male.clustered = false

      let maleLabel = male.bullets.push(new am4charts.LabelBullet())
      maleLabel.label.text = '{valueX}%'
      maleLabel.label.hideOversized = false
      maleLabel.label.truncate = false
      maleLabel.label.horizontalCenter = 'right'
      maleLabel.label.dx = -10

      let female = chart.series.push(new am4charts.ColumnSeries())
      female.dataFields.valueX = 'female'
      female.dataFields.categoryY = 'age'
      female.clustered = false

      let femaleLabel = female.bullets.push(new am4charts.LabelBullet())
      femaleLabel.label.text = '{valueX}%'
      femaleLabel.label.hideOversized = false
      femaleLabel.label.truncate = false
      femaleLabel.label.horizontalCenter = 'left'
      femaleLabel.label.dx = 10

      let maleRange = valueAxis.axisRanges.create()
      maleRange.value = -10
      maleRange.endValue = 0
      maleRange.label.text = 'Erkek'
      maleRange.label.fill = chart.colors.list[0]
      maleRange.label.dy = 20
      maleRange.label.fontWeight = '600'
      maleRange.grid.strokeOpacity = 1
      maleRange.grid.stroke = male.stroke

      let femaleRange = valueAxis.axisRanges.create()
      femaleRange.value = 0
      femaleRange.endValue = 10
      femaleRange.label.text = 'Kadın'
      femaleRange.label.fill = chart.colors.list[1]
      femaleRange.label.dy = 20
      femaleRange.label.fontWeight = '600'
      femaleRange.grid.strokeOpacity = 1
      femaleRange.grid.stroke = female.stroke
    }

    return () => {
      if (chart) {
        chart.dispose()
      }
    }
  }, [newData])

  return (
    <div
      className={`card bgi-no-repeat card-xl-stretch mb-xl-8 p-10`}
      style={{
        backgroundPosition: 'right top',
        backgroundSize: '30% auto',
        backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/abstract-4.svg')})`,
      }}
    >
      {/* <PageTitle>{intl.formatMessage({id: 'MENU.HRREPORT'})}</PageTitle> */}
      {/* <TabHr /> */}

      <div className=' row d-flex flex-column px-8 mt-16 '>
        <label className='col-md-4 col-12  fw-bold fs-3 pb-8 px-16 '>
          Kadın/Erkek Sters Verileri{' '}
        </label>
      </div>

      <div id='chartdiv938' style={{width: '100%', height: '500px'}}></div>
    </div>
  )
}
