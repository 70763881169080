import React, {useState, useEffect} from 'react'
import {ChartTotalTopic} from '../../../../_metronic/partials/widgets/charts/ChartsTotalTopic'
import {getCourseById} from '../../course/list/core/_requests'
import {ChartTotalQuiz} from '../../../../_metronic/partials/widgets/charts/ChartTotalQuiz'
import {getCourseTermById} from '../../courseterm/list/core/_requests'
import {TestStressReport} from '../../../../_metronic/partials/widgets/charts/NewTestStress'

type Props = {
  maxScore: any
  hrDimensionName: any
  userScore: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const StressBridge = ({userScore, hrDimensionName, maxScore}: Props) => {
  return (
    <TestStressReport maxScore={maxScore} hrDimensionName={hrDimensionName} userScore={userScore} />
  )
}

export {StressBridge}
