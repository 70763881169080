import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'

import {Questions} from './Questions'
import {AnnouncementList} from '../announcement/AnnouncementList'
import {MyTasks} from './myTasks'
import {MyQuiz} from './myQuiz'
import {MyCourse} from './myCourse'
import {MyQuestion} from './myQuestion'
import {Card5} from '../../../_metronic/partials/content/cards/Card5'
import {InfoCard} from './infoCard'
import {listApplicationUser} from '../applicationuser/list/core/_requests'
import {listCourse} from '../course/list/core/_requests'
import {listQuiz} from '../quiz/list/core/_requests'
import {listCertificate} from '../certificate/list/core/_requests'
import {ChartsCourseWidget} from './ChartWidget'
import {getPercentageReport} from '../course/list/core/_requests'
import {UserInfo} from './UserInfo'
import {MotivationSentence} from './motivationSentences'
import {MediaPlayer} from './mediaPlayer'
import {useAuth} from '../../modules/auth'
// import {Board} from './Board'

const DashboardPage = () => {
  const [user, setUser] = React.useState<any>([])
  const [course, setCourse] = React.useState<any>([])
  const [quiz, setQuiz] = React.useState<any>([])
  const [certificate, setCertificate] = React.useState<any>([])
  const [total, setTotal] = useState<any>()
  const [finished, setFinished] = useState<any>()
  const [inProgress, setInProgress] = useState<any>()
  const [notStarted, setnotStarted] = useState<any>()
  const [name, setName] = useState<any>()

  const {currentUser}: any = useAuth()
  console.log(currentUser)

  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  useEffect(() => {
    listApplicationUser('Id,Name').then((res: any) => {
      setUser(res.data.length)
    })
    listCourse('Id,Name').then((res: any) => {
      setCourse(res.data.length)
    })
    listQuiz('Id,Name').then((res: any) => {
      setQuiz(res.data.length)
    })
    listCertificate('Id,Name').then((res: any) => {
      setCertificate(res.data.length)
    })
  }, [])

  useEffect(() => {
    getPercentageReport('').then((res: any) => {
      const totals = res.data.map((item: any) => item.total)
      setTotal(totals)
      const finished = res.data.map((item: any) => item.finished)
      setFinished(finished)
      const inProgress = res.data.map((item: any) => item.inProgress)
      setInProgress(inProgress)
      const notStarted = res.data.map((item: any) => item.notStarted)
      setnotStarted(notStarted)
      const name = res.data.map((item: any) => item.name)
      setName(name)
    })
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        AnaSayfa
      </PageTitle>

      {/* begin::Row */}
      {currentUser?.modules.includes('HR') ? (
        <>
          <Questions />

          <MotivationSentence />
        </>
      ) : null}

      {/* <Board /> */}

      {/* <MediaPlayer /> */}

      {currentUser?.modules.includes('LMS') &&
      (currentUser?.roleName == 'CODE35' ||
        currentUser?.roleName == 'COMPANY ADMIN' ||
        currentUser?.roleName == 'INSTRUCTOR') ? (
        <>
          <div className='d-flex flex-md-row flex-column justify-content-between'>
            <InfoCard
              title='Kullanıcı Sayısı'
              user={user}
              image='/media/illustrations/dozzy-1/6.png'
            />
            <InfoCard
              title='Kurs Sayısı'
              user={course}
              image='/media/illustrations/dozzy-1/2.png'
            />
            <InfoCard title='Sınav Sayısı' user={quiz} image='/media/illustrations/sigma-1/4.png' />
            <InfoCard
              title='Sertifika Sayısı'
              user={certificate}
              image='/media/illustrations/dozzy-1/7.png'
            />
          </div>
          <div className='mt-7'>
            {name?.length > 0 &&
            notStarted?.length > 0 &&
            finished?.length > 0 &&
            inProgress?.length > 0 &&
            total?.length > 0 ? (
              <ChartsCourseWidget
                total={total}
                finished={finished}
                inProgress={inProgress}
                notStarted={notStarted}
                name={name}
                className=''
              />
            ) : null}
          </div>
        </>
      ) : null}

      {currentUser?.modules.includes('LMS') &&
      (currentUser?.roleName == 'PARTICIPANT' || currentUser?.roleName == 'CODE35') ? (
        <>
          <UserInfo />
        </>
      ) : null}
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
