import React, {useEffect, useState} from 'react'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import {getDailyReport} from '../Requests/_request'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const DailyReport = () => {
  const [newData, setNewData] = useState<any[]>([])
  const [list, setList] = useState<any[]>([])

  useEffect(() => {
    getDailyReport().then((res: any) => {
      console.log(res)
      let x = res.data.map((item: any, index: any) => ({
        category: index,
        value: Math.round((item.totalScore * 100) / item.maxScore),
      }))
      setNewData(x)

      let y = res.data.map((item: any, index: any) => ({
        index: index,
        dimension: item.dimensionName,
      }))
      setList(y)
    })
  }, [])
  useEffect(() => {
    if (newData.length > 0) {
      // Temayı kullanma
      am4core.useTheme(am4themes_animated)

      // Chart oluşturma
      let chart: any = am4core.create('chartdiv948', am4charts.RadarChart)
      chart.scrollbarX = new am4core.Scrollbar()

      let data = []
      for (let i = 0; i < 20; i++) {
        data.push({category: i, value: Math.round(Math.random() * 100)})
      }
      console.log(newData)
      chart.data = newData
      chart.radius = am4core.percent(100)
      chart.innerRadius = am4core.percent(50)

      // Ekseni oluşturma
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      categoryAxis.dataFields.category = 'category'
      categoryAxis.renderer.grid.template.location = 0
      categoryAxis.renderer.minGridDistance = 30
      categoryAxis.tooltip.disabled = true
      categoryAxis.renderer.minHeight = 110
      categoryAxis.renderer.grid.template.disabled = true

      let labelTemplate = categoryAxis.renderer.labels.template
      labelTemplate.radius = am4core.percent(-60)
      labelTemplate.location = 0.5
      labelTemplate.relativeRotation = 90

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())
      valueAxis.renderer.grid.template.disabled = true
      valueAxis.renderer.labels.template.disabled = true
      valueAxis.tooltip.disabled = true

      // Seriyi oluşturma
      let series = chart.series.push(new am4charts.RadarColumnSeries())
      series.sequencedInterpolation = true
      series.dataFields.valueY = 'value'
      series.dataFields.categoryX = 'category'
      series.columns.template.strokeWidth = 0
      series.tooltipText = '{valueY}'
      series.columns.template.radarColumn.cornerRadius = 10
      series.columns.template.radarColumn.innerCornerRadius = 0
      series.tooltip.pointerOrientation = 'vertical'

      let hoverState = series.columns.template.radarColumn.states.create('hover')
      hoverState.properties.cornerRadius = 0
      hoverState.properties.fillOpacity = 1

      series.columns.template.adapter.add('fill', function (fill: any, target: any) {
        return chart.colors.getIndex(target.dataItem.index)
      })

      // Cursor
      chart.cursor = new am4charts.RadarCursor()
      chart.cursor.innerRadius = am4core.percent(50)
      chart.cursor.lineY.disabled = true

      // Komponentin kaldırıldığında temizlik yapma
      return () => {
        if (chart) {
          chart.dispose()
        }
      }
    }
  }, [newData])
  console.log(list)
  return (
    <div
      className={`card bgi-no-repeat card-xl-stretch mb-xl-8 p-10`}
      style={{
        backgroundPosition: 'right top',
        backgroundSize: '30% auto',
        backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/abstract-4.svg')})`,
      }}
    >
      <div className='row d-flex flex-column px-8 mt-16'>
        <label className='col-md-4 col-12 fw-bold fs-3 pb-md-8 px-16'>Günlük Mutluluk Raporu</label>
      </div>

      <div className='container'>
        <div className='d-flex flex-column'>
          <div className='col-12'>
            <div id='chartdiv948' style={{width: '100%', height: '500px'}}></div>
          </div>
          <div className='pt-16 d-flex flex-row align-items-md-center align-items-start  '>
            {list?.map((item: any) => {
              return (
                // <p>
                //   {item.index}:{item.dimension}
                // </p>
                <>
                  <div className='timeline-item d-flex flex-row gap-2  '>
                    {/* begin::Label */}
                    {/* <div className=' fw-bold text-gray-800 fs-6 '>{item.index}=</div> */}
                    {/* end::Label */}
                    {/* begin::Badge */}
                    <div className='timeline-badge '>
                      {/* <i className={`fa fa-genderless text-warning fs-1`}></i> */}
                    </div>
                    <p></p>
                    {/* end::Badge */}
                    {/* begin::Text */}
                    <div
                      className=' ps-3 fs-6 md:fs-4  d-flex   flex-wrap'
                      // style={{textAlign: 'justify'}}
                    >
                      <span className='fw-bold'>
                        {item.index} = {item.dimension && item.dimension}{' '}
                      </span>{' '}
                      {/* <span className='text-muted '> {item.text && item.text}</span>{' '} */}
                    </div>
                  </div>
                </>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DailyReport
