/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import qs from 'qs'
import {Tab} from '../report-tabs/Tab'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {getCourseSuccessReport, getCourseSuccessReport2} from '../Requests/_request'
import {CourseBridge} from './courseBirdge'
import {listCourseTerm} from '../../courseterm/list/core/_requests'
import {listCourse} from '../../course/list/core/_requests'
import {listCourseTermByCourseId} from '../../courseterm/list/core/_requests'

export function GeneralQuiz() {
  const intl = useIntl()

  const [newData, setNewData] = useState<any[]>([])
  const [course, setcourses] = useState<any[]>([])

  const [coursTerms, setcourseTerms] = useState<any[]>([])
  const [selectedCoursTerm, setSelectedCourseTerm] = useState<any[]>([])

  useEffect(() => {
    getCourseSuccessReport().then((res: any) => {
      console.log(res)
      setNewData(res.data)
    })

    listCourseTerm('Id,Name').then((res: any) => {
      setcourseTerms(res.data)
    })
  }, [])

  const GetChart = (termId: any) => {
    getCourseSuccessReport2(termId).then((res: any) => {
      setNewData(res.data)
    })
  }

  console.log(newData)

  return (
    <>
      {/* <PageTitle>{intl.formatMessage({id: 'General_Reports'})}</PageTitle>
      <Tab /> */}
      <div className='card-title m-0'>
        <h3 className='fw-bolder m-0 mb-4 mt-16 p-2'>Katılım Grafikleri</h3>
      </div>
      <div className=' card p-10 rounded-0 '>
        {/* <label className=' fw-bold fs-6 '>{intl.formatMessage({id: 'MENU.COURSE'})}</label>
        <select
          className='form-select form-select-solid form-select-md'
          onChange={(e: any) => getCourseTerms(e.target.value)}
        >
          <option value=''>{intl.formatMessage({id: 'SEÇİNİZ'})}</option>
          {course.map((myval: any) => (
            <option value={myval.id} key={myval?.id as any}>
              {myval?.name as any}
            </option>
          ))}
        </select> */}

        <label className=' fw-bold fs-6 mb-2 mt-7'>{intl.formatMessage({id: 'CourseTerm'})}</label>
        <select
          className='form-select form-select-solid form-select-md'
          onChange={(e: any) => GetChart(e.target.value)}
        >
          <option value=''>{intl.formatMessage({id: 'SEÇİNİZ'})}</option>
          {coursTerms.map((myval: any) => (
            <option value={myval.id} key={myval?.id as any}>
              {myval?.name as any}
            </option>
          ))}
        </select>
      </div>

      {newData.length > 0 &&
        newData?.map((item: any) => {
          const termName = item.courseTerm
          const quizName = item?.successes?.map((item2: any) => item2.quiz)
          const totalUser = item?.successes?.map((item2: any) => item2.totalUser)
          const passedUser = item?.successes?.map((item2: any) => item2.passedUser)
          const failedUser = item?.successes?.map((item2: any) => item2.failedUser)

          return (
            <CourseBridge
              className=''
              termName={termName}
              quizName={quizName}
              totalUser={totalUser}
              passedUser={passedUser}
              failedUser={failedUser}
            />
          )
        })}
    </>
  )
}
