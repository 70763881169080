import axios, {AxiosResponse} from 'axios'

import {ID, Response} from '../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_API_URL
const DailyQuestion = `${API_URL}/Custom/getUserQuestion`
const NOTIFICATION = `${API_URL}/Custom/sendCustomNotification`

const getUserQuestion = (): Promise<any | undefined> => {
  // return axios
  //   .get(`${DailyQuestion}`)
  //   .then((response: AxiosResponse<Response<any>>) => response.data)
  //   .then((response: Response<any>) => response as any)

  return axios.get(`${DailyQuestion}`).then((response) => response)
}

const CustomNotification = (prop: any): Promise<any | undefined> => {
  // return axios
  // .post(`${NOTIFICATION}`, prop)
  // .then((response: AxiosResponse<Response<any>>) => response.data)
  // .then((response: Response<any>) => response.data)

  return axios.post(`${NOTIFICATION}`, prop).then((response) => response)
}

export {getUserQuestion, CustomNotification}
