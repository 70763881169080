import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {Tab} from '../Tabs/Tab'
import qs from 'qs'
import {NewUserTopic} from '../../../../_metronic/partials/widgets/charts/NewuserTopic'

import {getTopicbyUserId} from '../_requests'

const Topic: FC = () => {
  const [data, setData] = useState<any>([])

  const intl = useIntl()

  useEffect(() => {
    const qsd = qs.parse(window.location.search, {ignoreQueryPrefix: true}).userId ?? undefined

    getTopicbyUserId(qsd).then((res2: any) => {
      console.log(res2)
      setData(res2.data)
    })
  }, [])

  console.log(data)

  return (
    <>
      <PageTitle>{intl.formatMessage({id: 'USERCOURSEREPORTS'})}</PageTitle>

      <Tab />

      <div className='row'>
        {data.length &&
          data?.map((course: any, index: number) => {
            const totalFalse = course?.userTopicReportViewModels?.map(
              (item2: any) => item2.totalFalse
            )
            const totalTrue = course?.userTopicReportViewModels?.map(
              (item2: any) => item2.totalTrue
            )
            const totalQuestion = course?.userTopicReportViewModels?.map(
              (item2: any) => item2.totalQuestion
            )
            const topicName = course?.userTopicReportViewModels?.map((item2: any) => item2.topic)
            const accordionId = `accordion_${index}`
            const headerId = `accordion_header_${index}`
            const bodyId = `accordion_body_${index}`
            return (
              <div className='accordion' id={accordionId} key={index}>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id={headerId}>
                    <button
                      className='accordion-button fs-4 fw-bold collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target={`#${bodyId}`}
                      aria-expanded='false'
                      aria-controls={bodyId}
                    >
                      {course?.courseName}
                    </button>
                  </h2>
                  <div
                    id={bodyId}
                    className='accordion-collapse collapse'
                    aria-labelledby={headerId}
                    data-bs-parent={`#${accordionId}`}
                  >
                    <div className='accordion-body'>
                      <div className='row  gap-16 d-flex justify-content-center'>
                        <div className='accordion-body'>
                          <NewUserTopic
                            className={''}
                            totalFalse={totalFalse}
                            totalTrue={totalTrue}
                            totalQuestion={totalQuestion}
                            topicName={topicName}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </>
  )
}

export {Topic}
