import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import React, {useState, useRef, useEffect} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {getUserQuestion} from './_requests'
import Swal from 'sweetalert2'

const DailyNotification = () => {
  const intl = useIntl()

  const SendHrNotification = () => {
    getUserQuestion().then((res: any) => {
      if (res.status == 200) {
        Swal.fire({
          title: intl.formatMessage({id: 'SWEETALERT.SUCCESS'}),
          // text: intl.formatMessage({id: 'SWEETALERT.DELETED'}),
          icon: 'success',
          timer: 2000,
          showConfirmButton: true,
        }).then(() => {})
      } else {
        Swal.fire({
          title: intl.formatMessage({id: 'SWEETALERT.FAIL'}),
          // text: intl.formatMessage({id: 'SWEETALERT.DELETED'}),
          icon: 'warning',
          timer: 2000,
          showConfirmButton: true,
        })
      }
    })
  }
  return (
    <>
      <div className={`card mt-7`}>
        {/* begin::Body */}
        <div className='card-body pb-0'>
          {/* begin::Header */}
          <div className='d-flex align-items-center mb-5'>
            {/* begin::User */}
            <div className='d-flex align-items-center flex-grow-1'>
              {/* begin::Avatar */}
              {/* <div className='symbol symbol-45px me-5'>
                <img src={toAbsoluteUrl('/media/avatars/300-23.jpg')} alt='' />
              </div> */}
              {/* end::Avatar */}

              {/* begin::Info */}
              <div className='d-flex flex-column'>
                <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                  HR Günlük Soru Bildirimi
                </a>

                <span className='text-gray-400 fw-semibold'>
                  Kullanıcılara bildirim göndermek için butona basınız
                </span>
              </div>
              {/* end::Info */}
            </div>
            {/* end::User */}

            {/* begin::Menu */}
            {/* <div className='my-0'>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTIcon iconName='category' className='fs-2' />
              </button>
            </div> */}
            {/* end::Menu */}
          </div>
          {/* end::Header */}

          {/* begin::Post */}
          <div className='mb-5'></div>
          {/* end::Post */}

          {/* begin::Separator */}
          <div className='separator mb-4'></div>
          {/* end::Separator */}

          {/* begin::Reply input */}
          <button
            type='submit'
            className='btn btn-primary mb-6  '
            data-kt-items-modal-action='submit'
            onClick={(e) => {
              SendHrNotification()
            }}
          >
            <span className='indicator-label'> Günlük Bildirim Gönder</span>
          </button>

          {/* edit::Reply input */}
        </div>
        {/* end::Body */}
      </div>
    </>
  )
}

export default DailyNotification
