import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Survey, SurveyAnswerList} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const THING_URL = `${API_URL}/Survey`
const ADD_SURVEY_ANSWER_URL = `${API_URL}/Custom/addSurveyAnswer`
const GET_SURVEY_ID_URL = `${API_URL}/Custom/getSurveyId`

const getThingById = (id: string): Promise<Survey | undefined> => {
  return axios
    .get(`${THING_URL}/${id}?modelstoinclude=SurveyGroup.SurveyQuestion`)
    .then((response: AxiosResponse<Response<Survey>>) => response.data)
    .then((response: Response<Survey>) => response as any)
}

const getSurveyId = (QuizId: any): Promise<any> => {
  return axios.get(`${GET_SURVEY_ID_URL}?Id=${QuizId}`).then((res: AxiosResponse) => {
    return res.data
  })
}

const addSurveyAnswer = (surveyanswer: SurveyAnswerList): Promise<any> => {
  return axios.put(`${ADD_SURVEY_ANSWER_URL}`, surveyanswer).then((d: AxiosResponse<any>) => d.data)
}
export {getThingById, addSurveyAnswer, getSurveyId}
