import React, { FC, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { getCourseTermForSell } from './list/core/_requests'
import foto from './img/VISA.jpg'


interface CourseTerm {
  id: string
  courseId: string
  description: string
  endDay: string
  name: string
  startDay: string
  price: string
}

const CourseTermSell: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [cardNo, setCardNo] = useState<string>('')
  const [month, setMonth] = useState<string>('')
  const [year, setYear] = useState<string>('')
  const [cvv, setCvv] = useState<string>('')
  const [isWallet, setIsWallet] = useState<boolean>(false)
  const [data, setData] = useState<CourseTerm[]>([])
  const [basket, setBasket] = useState<CourseTerm[]>([])
  const [totalPrice, setTotalPrice] = useState<string>('')

  useEffect(() => {
    getCourseTermForSell().then((res) => {
      setData(res.data || [])
    })
  }, [])

  const formatCardNumber = (input: string) => {
    const numericInput = input.replace(/\D/g, '')
    const groups = numericInput.match(/.{1,4}/g)
    return groups ? groups.join(' ') : ''
  }

  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedNumber = formatCardNumber(e.target.value)
    setCardNo(formattedNumber)
  }

  const completePayment = () => {
    localStorage.setItem('terms', JSON.stringify(basket))
    navigate(`/courseterm/invoice`)
  }

  const addToBasket = (item: CourseTerm) => {
    if (!basket.some(basketItem => basketItem.id === item.id)) {
      setBasket(prevBasket => [...prevBasket, item])
    }
  }

  const deleteItem = (id: string) => {
    setBasket(prevBasket => prevBasket.filter(item => item.id !== id))
  }

  const clearBasket = () => {
    setBasket([])
  }

  const findTotal = () => {
    const totalSum = basket
      .map(item => parseFloat(item.price))
      .reduce((acc, curr) => acc + curr, 0)
      .toFixed(2)

    setTotalPrice(totalSum)
  }

  const checkboxStyle = {
    transform: 'scale(1.5)', // Checkbox'ı büyütmek için transform özelliğini kullanıyoruz.
  }

  useEffect(() => {
    findTotal()
  }, [basket])

  const openPdf = () => {
    window.open('https://theapi.tonyuk.com/Files/mesafeli-satis-sozlesmesi.pdf', '_blank')
  }

  return (
    <div className='row'>
      <div className='tab-content col-8'>
        {/* <!--begin::Tap pane--> */}
        <div className='tab-pane fade show active'>
          {/* <!--begin::Wrapper--> */}
          <div className='d-flex flex-wrap d-grid gap-5 gap-xxl-9 row'>
            {/* <!--begin::Card--> */}
            {data?.length > 0 &&
              data?.map((item: any) => {
                return (
                  <div className='card card-flush flex-row-fluid p-6 pb-5 mw-100 col-3'>
                    {/* <!--begin::Body--> */}
                    <div className='card-body text-center'>
                      {/* <!--begin::Food img--> */}
                      {/* <img
                    src='assets/media/stock/food/img-2.jpg'
                    className='rounded-3 mb-4 w-150px h-150px w-xxl-200px h-xxl-200px'
                    alt=''
                  /> */}
                      {/* {item.imagePath ? (
                      <img
                        
                        className='rounded-3 mb-4 w-150px h-150px w-xxl-200px h-xxl-200px'
                        src={`https://ifittapi.code35yazilim.com/${item.imagePath}`}
                      />
                    ) : 
                    null} */}
                      {/* <!--end::Food img--> */}
                      {/* <!--begin::Info--> */}
                      <div className='mb-2'>
                        {/* <!--begin::Title--> */}
                        {/* <Link to={`/product/detail?productId=${item.id}`}> */}
                        <div className='text-center'>
                          <span className='fw-bold text-gray text-end fw-bold fs-1 cursor-pointer text-hover-primary mt-5 '>
                            {item?.name}
                          </span>
                          <span className='text-gray-400 fw-semibold d-block fs-6 mt-n1 pt-1'>
                            {moment(item?.startDay).format('YYYY-MM-DD')}
                          </span>

                          <span className='text-gray-400 fw-semibold d-block fs-6 mt-n1 pt-1'>
                            {moment(item?.endDay).format('YYYY-MM-DD')}
                          </span>
                        </div>
                        {/* </Link> */}
                        {/* <!--end::Title--> */}
                      </div>
                      {/* <!--end::Info--> */}
                      {/* <!--begin::Total--> */}
                      <span className='text-success text-end fw-bold fs-1'>
                        {' '}
                        {parseFloat(item.price).toFixed(2)} TL
                      </span>
                      {/* <!--end::Total--> */}
                    </div>
                    <a
                      href='#'
                      className='btn btn-light-primary   fs-4 fw-bold  py-4 '
                      onClick={(e: any) => addToBasket(item)}
                    >
                      Sepete Ekle
                    </a>
                    {/* <!--end::Body--> */}
                  </div>
                )
              })}

            {/* <!--end::Card!!!!!!!!!!!!!!!!!--> */}
            {/* <!--begin::Card--> */}

            {/* <!--end::Card--> */}
          </div>
          {/* <!--end::Wrapper--> */}
        </div>
        {/* <!--end::Tap pane--> */}
        {/* <!--begin::Tap pane--> */}

        {/* <!--end::Tap pane--> */}
      </div>
      <div className='flex-row-auto col-4 '>
        {/* <!--begin::Pos order--> */}
        <div className='card card-flush bg-body' id='kt_pos_form'>
          {/* <!--begin::Header--> */}
          <div className='card-header pt-5'>
            <h3 className='card-title fw-bold text-gray-800 fs-2qx'>Sepetiniz</h3>
            {/* <!--begin::Toolbar--> */}
            <div className='card-toolbar'>
              <a
                href='#'
                className='btn btn-light-primary fs-4 fw-bold py-4 '
                onClick={(e: any) => clearBasket()}
              >
                Sepeti Temizle
              </a>
            </div>
            {/* <!--end::Toolbar--> */}
          </div>
          {/* <!--end::Header--> */}
          {/* <!--begin::Body--> */}
          <div className='card-body pt-0'>
            {/* <!--begin::Table container--> */}
            <div className='table-responsive mb-8'>
              {/* <!--begin::Table--> */}
              <table className='table align-middle gs-0 gy-4 my-0'>
                {/* <!--begin::Table head--> */}
                <thead>
                  <tr>
                    <th className='min-w-175px'></th>
                    <th className='w-125px'></th>
                    <th className='w-60px'></th>
                  </tr>
                </thead>
                {/* <!--end::Table head--> */}
                {/* <!--begin::Table body--> */}

                <tbody>
                  <tr data-kt-pos-element='item' data-kt-pos-item-price='33'>
                    <td className='pe-0'>
                      <div className='d-flex align-items-center'>
                        {/* <img
                              src={`https://enterapi.code35yazilim.com/${item.imagePath}`}
                              className='w-50px h-50px rounded-3 me-3'
                              alt=''
                            /> */}
                        <span className='fw-bold text-gray-800 cursor-pointer text-hover-primary fs-3 me-1'>
                          Ürün
                        </span>
                      </div>
                    </td>

                    <td className='text-start'>
                      <span
                        className='fw-bold text-gray-800 cursor-pointer text-hover-primary fs-3 me-1'
                        data-kt-pos-element='item-total'
                      >
                        Fiyat
                      </span>
                    </td>

                    {/* <td className='text-center'>
                      <span
                        className='fw-bold text-gray-800 cursor-pointer text-hover-primary fs-3 me-1'
                        data-kt-pos-element='item-total'
                      >
                        Vat
                      </span>
                    </td> */}
                  </tr>
                  <tr data-kt-pos-element='item' data-kt-pos-item-price='33'></tr>
                  {basket.length > 0 &&
                    basket?.map((item: any) => {
                      return (
                        <tr data-kt-pos-element='item' data-kt-pos-item-price='33'>
                          <td className='pe-0 '>
                            <div className='d-flex align-items-center'>
                              {/* <img
                                src={`https://ifittapi.code35yazilim.com/${item.imagePath}`}
                                className='w-50px h-50px rounded-3 me-3'
                                alt=''
                              /> */}
                              <span className='fw-bold text-gray-800  cursor-pointer text-hover-primary fs-6 me-1'>
                                {item?.name}
                              </span>
                            </div>
                          </td>

                          <td className='text-start '>
                            <span
                              className='fw-bold text-primary fs-4'
                              data-kt-pos-element='item-total'
                            >
                              {parseFloat(item.price).toFixed(2)} TL
                            </span>
                          </td>

                          <td>
                            <span className='symbol symbol-30px'>
                              <span
                                className={`symbol-label fs-5 fw-bold bg-light-danger text-danger cursor-pointer`}
                                onClick={(e: any) => deleteItem(item)}
                              >
                                X
                              </span>
                            </span>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
                {/* <!--end::Table body--> */}
              </table>
              {/* <!--end::Table--> */}
            </div>
            {/* <!--end::Table container--> */}
            {/* <!--begin::Summary--> */}
            <div className='d-flex flex-stack bg-success rounded-3 p-6 mb-11'>
              {/* <!--begin::Content--> */}
              <div className='fs-6 fw-bold text-white'>
                {/* <span className='d-block lh-1 mb-2'>Amount</span>
                    {/* <span className='d-block mb-2'>Discounts</span>
                    <span className='d-block mb-9'>VAT (%20)</span> */}

                <span className='d-block fs-2qx lh-1'>Toplam</span>
              </div>
              {/* <!--end::Content--> */}
              {/* <!--begin::Content--> */}
              <div className='fs-6 fw-bold text-white text-end'>
                {/* <span className='d-block lh-1 mb-2' data-kt-pos-element='total'>
                      {price?.toFixed(2)}€
                    </span> */}
                {/* <span className='d-block mb-2' data-kt-pos-element='discount'>
                      -$8.00
                    </span> */}

                <span className='d-block fs-2qx lh-1' data-kt-pos-element='grant-total'>
                  {/* {price * 1.2}TL */}
                  {totalPrice} TL
                </span>
              </div>
              {/* <!--end::Content--> */}
            </div>
            {/* <!--end::Summary--> */}
            {/* <!--begin::Payment Method--> */}
            <div className='m-0'>
              {/* <!--begin::Title--> */}
              <div className='d-flex flex-row justify-content-between mb-7'>
                <h1 className='fw-bold text-gray-800 pt-3 '>Ödeme İşlemleri</h1>
                <div className='d-flex align-items-center '>
                  <img src={foto} className='w-100px h-50px rounded-3 me-3' alt='' />
                </div>
              </div>
              {/* <!--end::Title--> */}
              {/* <!--begin::Radio group--> */}

              <div className='fv-row mb-7'>
                    <label className='required fw-bold fs-6 mb-2'>Kart No </label>
                    <input
                      type='text'
                      name='name'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                      maxLength={19}
                      value={cardNo}
                      // onChange={(e: any) => setcardNo(e.target.value)}
                      onChange={handleCardNumberChange}
                    />
                  </div>
                  <div className='d-flex row gap-5 mb-7 mt-10'>
                    <label className='required fw-bold fs-6 '>SKT / CVV</label>
                    <div className='fv-row mb-7 col-3'>
                      <div className='row d-flex '>
                        <input
                          type='text'
                          name='name'
                          className='form-control form-control-solid mb-3 mb-lg-0 col-6'
                          autoComplete='off'
                          placeholder='11'
                          maxLength={2}
                          value={month}
                          onChange={(e: any) => setMonth(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='fv-row mb-7 col-3'>
                      <div className='row d-flex '>
                        <input
                          type='text'
                          name='name'
                          className='form-control form-control-solid mb-3 mb-lg-0 col-6'
                          autoComplete='off'
                          placeholder='24'
                          maxLength={2}
                          value={year}
                          onChange={(e: any) => setYear(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='fv-row mb-7 col-4'>
                      <div className='row d-flex '>
                        <input
                          type='text'
                          name='name'
                          className='form-control form-control-solid mb-3 mb-lg-0 col-6'
                          autoComplete='off'
                          placeholder='CVV'
                          maxLength={3}
                          value={cvv}
                          onChange={(e: any) => setCvv(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className='fv-row mb-7 '>
                      <input
                        type='checkbox'
                        name='name'
                        className=''
                        autoComplete='off'
                        maxLength={19}
                        style={checkboxStyle}
                      />
                      <label className='fw-bold fs-7 m-3 '>
                        <span onClick={openPdf} className='text-primary cursor-pointer'>
                          Mesafeli Satış Sözleşmesi
                        </span>
                        'ni Okudum Onaylıyorum
                      </label>
                    </div>
                  </div>

              <button
                onClick={(e: any) => completePayment()}
                className='btn btn-primary fs-1 w-100 py-4'
              >
                Ödemeyi Tamamla
              </button>
              {/* <!--end::Actions--> */}
            </div>

            {/* <!--end::Payment Method--> */}
          </div>
          {/* <!--end: Card Body--> */}
        </div>
        {/* <!--end::Pos order--> */}
      </div>
    </div>
  )
}

export default CourseTermSell
