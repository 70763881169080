import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {Tab} from '../Tabs/Tab'
import {ChartsWidgetGeneral} from '../../../../_metronic/partials/widgets/charts/ChartsWidgetGeneral'
import {getQuizbyUserId, getGeneralQuiz} from '../_requests'
import qs from 'qs'
import {UserQuizCharts} from '../../../../_metronic/partials/widgets/charts/NewUserQuizWidget1'
import {ChartsBridge} from './ChartBridge'

const Quiz2: FC = () => {
  //   const [data, setData] = useState<any>(undefined)
  const [groupedData, setGroupedData] = useState<any>([])
  const [categories, setCategories] = useState<any>(undefined)
  const [generalData, setGeneralData] = useState<any>([])

  const intl = useIntl()

  useEffect(() => {
    const qsd = qs.parse(window.location.search, {ignoreQueryPrefix: true}).userId ?? undefined
    getQuizbyUserId(qsd).then((res2: any) => {
      res2.data.forEach((item: any) => {
        const courseTermId = item.courseTermId
        console.log(groupedData)
        if (!groupedData[courseTermId]) {
          console.log('xx')
          groupedData[courseTermId] = []
        }
        groupedData[courseTermId].push(item)
      })

      const groupedDataArray = Object.entries(groupedData).map(([key, value]) => ({
        courseTermId: Number(key),
        data: value,
      }))

      setGroupedData(groupedDataArray)
    })

    getGeneralQuiz(qsd).then((res: any) => {
      console.log(res.data)
      setGeneralData(res.data)
    })
  }, [])

  console.log(groupedData)
  console.log(groupedData)

  return (
    <>
      <PageTitle>{intl.formatMessage({id: 'USERCOURSEREPORTS'})}</PageTitle>

      <Tab />
      <div className='row'>
        {groupedData.length &&
          groupedData?.map((course: any, index: number) => {
            // const average = course?.userQuizReportViewModels?.map((item2: any) => item2.average)
            // const passMark = course?.userQuizReportViewModels?.map((item2: any) => item2.passMark)
            // const quizName = course?.userQuizReportViewModels?.map((item2: any) => item2.quizName)
            const accordionId = `accordion_${index}`
            const headerId = `accordion_header_${index}`
            const bodyId = `accordion_body_${index}`
            return (
              <div className='accordion' id={accordionId} key={index}>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id={headerId}>
                    <button
                      className='accordion-button fs-4 fw-bold collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target={`#${bodyId}`}
                      aria-expanded='false'
                      aria-controls={bodyId}
                    >
                      {course?.data[0].courseTermName}
                    </button>
                  </h2>
                  <div
                    id={bodyId}
                    className='accordion-collapse collapse'
                    aria-labelledby={headerId}
                    data-bs-parent={`#${accordionId}`}
                  >
                    {course &&
                    course.data &&
                    course.data[0] &&
                    course.data[0].userQuizReportViewModels
                      ? (() => {
                          const newData: any = []
                          course.data[0].userQuizReportViewModels.map((quiz: any) => {
                            const foundCourse = newData.find(
                              (item: any) => item?.courseId === quiz?.courseId
                            )
                            if (foundCourse) {
                              foundCourse.quizs.push(quiz)
                            } else {
                              const newCourse = {
                                courseId: quiz.courseId,
                                courseName: quiz.courseName,
                                quizs: [quiz],
                              }
                              newData.push(newCourse)
                            }
                          })

                          console.log(newData)

                          const veri = newData?.map((item: any, index2: any) => {
                            const average = item?.quizs.map((item2: any) => item2.average)
                            const passMark = item?.quizs.map((item2: any) => item2.passMark)
                            const quizName = item?.quizs.map((item2: any) => item2.quizName)
                            const courseName = item.courseName
                            // const passMark = course?.userQuizReportViewModels?.map((item2: any) => item2.passMark)
                            // const quizName = course?.userQuizReportViewModels?.map((item2: any) => item2.quizName)
                            return (
                              <ChartsBridge
                                className={''}
                                average={average}
                                quizName={quizName}
                                passMark={passMark}
                                courseName={courseName}
                              />
                            )
                          })
                          return veri
                        })()
                      : null}
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </>
  )
}

export {Quiz2}
