import React, {useState, useEffect} from 'react'
import {ChartTotalTopic} from '../../../../_metronic/partials/widgets/charts/ChartsTotalTopic'
import {getCourseById} from '../../course/list/core/_requests'

type Props = {
  className: string
  topicName: any
  percentage: any
  courseId: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const TopicChartsBridge = ({className, topicName, percentage, courseId}: Props) => {
  const [courseName, setCourseName] = useState()
  // useEffect(() => {
  //   getCourseById(courseId).then((res: any) => {
  //     setCourseName(res.name)
  //   })
  // }, [])

  console.log(courseName)
  console.log(percentage)
  console.log(topicName)
  return (
    <ChartTotalTopic
      className=''
      topicName={topicName}
      percentage={percentage}
      courseName={courseName}
    />
  )
}

export {TopicChartsBridge}
