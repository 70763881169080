import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {KTIcon} from '../../../_metronic/helpers'
import React, {useState, useRef, useEffect} from 'react'
import {CustomNotification} from './_requests'
import {useIntl} from 'react-intl'
import {listApplicationUser} from '../applicationuser/list/core/_requests'
import {Field, ErrorMessage} from 'formik'
import Swal from 'sweetalert2'
import {listOrganization} from '../organization/list/core/_requests'
import {getApplicationUserByOrnagizationId} from '../applicationuser/list/core/_requests'

const SingleNotification = () => {
  const [title, setTitle] = useState<any>('')
  const [text, setText] = useState<any>('')
  const [users, setUsers] = useState<any>(undefined)
  const [selectedUsers, setSelectedUsers] = useState<any>([])
  const [organizations, setOrganizations] = useState<any>([])
  const [selectedOrganizations, setSelectedOrganizations] = useState<any>([])

  const intl = useIntl()

  useEffect(() => {
    listApplicationUser('Id,Name').then((res: any) => {
      setUsers(res.data || [])
    })

    listOrganization('Id,Name').then((res: any) => {
      setOrganizations(res.data || [])
    })
  }, [])

  console.log(organizations)

  const SendNotification = () => {
    selectedUsers.length == 1
      ? CustomNotification({title: title, text: text, userId: selectedUsers[0]}).then(
          (res: any) => {
            setText('')
            setTitle('')
            if (res.status == 200) {
              Swal.fire({
                title: intl.formatMessage({id: 'SWEETALERT.SUCCESS'}),
                // text: intl.formatMessage({id: 'SWEETALERT.DELETED'}),
                icon: 'success',
                timer: 2000,
                showConfirmButton: true,
              }).then(() => {})
            } else {
              Swal.fire({
                title: intl.formatMessage({id: 'SWEETALERT.FAIL'}),
                // text: intl.formatMessage({id: 'SWEETALERT.DELETED'}),
                icon: 'warning',
                timer: 2000,
                showConfirmButton: true,
              })
            }
          }
        )
      : CustomNotification({title: title, text: text, userIds: selectedUsers}).then((res: any) => {
          setText('')
          setTitle('')
          if (res.status == 200) {
            Swal.fire({
              title: intl.formatMessage({id: 'SWEETALERT.SUCCESS'}),
              // text: intl.formatMessage({id: 'SWEETALERT.DELETED'}),
              icon: 'success',
              timer: 2000,
              showConfirmButton: true,
            }).then(() => {})
          } else {
            Swal.fire({
              title: intl.formatMessage({id: 'SWEETALERT.FAIL'}),
              // text: intl.formatMessage({id: 'SWEETALERT.DELETED'}),
              icon: 'warning',
              timer: 2000,
              showConfirmButton: true,
            })
          }
        })
  }

  const selectDepartman = (id: any) => {
    if (id == 'null') {
      listApplicationUser('Id,Name').then((res: any) => {
        setUsers(res.data || [])
      })
    } else {
      getApplicationUserByOrnagizationId(id).then((res: any) => {
        setUsers(res.data || [])
      })
    }
  }
  console.log(selectedUsers)
  console.log(selectedUsers.length)
  return (
    <>
      <div className={`card mt-16`}>
        {/* begin::Body */}
        <div className='card-body pb-0'>
          {/* begin::Header */}
          <div className='d-flex align-items-center mb-5'>
            {/* begin::User */}
            <div className='d-flex align-items-center flex-grow-1'>
              {/* begin::Avatar */}
              {/* <div className='symbol symbol-45px me-5'>
                <img src={toAbsoluteUrl('/media/avatars/300-23.jpg')} alt='' />
              </div> */}
              {/* end::Avatar */}

              {/* begin::Info */}
              <div className='d-flex flex-column w-100'>
                <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                  Özel Bildirim
                </a>

                <span className='text-gray-400 fw-semibold'>
                  Kullanıcılar arasında özel bildirim göndermek için başlık ve içerik girip ardından
                  butona basınız
                </span>
                <div className='d-flex flex-row row w-100'>
                  <div className='d-flex flex-column col-6  mt-7 justify-center alings-item-center '>
                    <div className='fv-row  col-12'>
                      <label className=' fw-bold fs-6 mb-2'>Başlık</label>
                      <input
                        type='text'
                        name='content'
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        autoComplete='off'
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>

                    <div className='fv-row mt-7  col-12'>
                      <label className=' fw-bold fs-6 mb-2'>Bildirim İçeriği</label>
                      <textarea
                        rows={7}
                        name='content'
                        value={text}
                        className='form-control form-control-solid mb-3 mb-lg-0'
                        onChange={(e) => setText(e.target.value)}
                        autoComplete='off'
                      />
                    </div>
                  </div>
                  <div className='d-flex flex-column col-6  mt-7 justify-center alings-item-center'>
                    <div className='fv-row mb-7 col-12'>
                      <label className='required fw-bold fs-6 mb-2'>Departman Seç</label>
                      <select
                        className='form-select form-select-solid form-select-md'
                        onChange={(e: any) => selectDepartman(e.target.value)}
                      >
                        <option value='null'>{intl.formatMessage({id: 'SEÇİNİZ'})}</option>

                        {organizations?.map((myval: any) => (
                          <option value={myval?.id} key={myval?.id as any}>
                            {myval?.name as any}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className='fv-row  col-12'>
                      <label className='required fw-bold fs-6 mb-2'>Kullanıcı Seçiniz</label>
                      <select
                        className='form-select form-select-solid form-select-md'
                        multiple // Çoklu seçimi aktif hale getiriyoruz
                        value={selectedUsers} // Seçili değerleri state'den alıyoruz
                        onChange={(e) =>
                          setSelectedUsers(
                            Array.from(e.target.selectedOptions, (option) => option.value)
                          )
                        }
                        style={{height: '200px'}}
                      >
                        {users?.map((myval: any) => (
                          <option value={myval?.id} key={myval?.id as any}>
                            {myval?.name as any}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {/* end::Info */}
            </div>
            {/* end::User */}

            {/* begin::Menu */}
            {/* <div className='my-0'>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTIcon iconName='category' className='fs-2' />
              </button>
            </div> */}
            {/* end::Menu */}
          </div>
          {/* end::Header */}

          {/* begin::Post */}
          <div className='mb-5'></div>
          {/* end::Post */}

          {/* begin::Separator */}
          <div className='separator mb-4'></div>
          {/* end::Separator */}

          {/* begin::Reply input */}

          <button
            type='submit'
            className='btn btn-primary mb-6 '
            data-kt-items-modal-action='submit'
            disabled={title == '' || text == '' || selectedUsers.length == 0}
            onClick={(e) => {
              SendNotification()
            }}
          >
            <span className='indicator-label'> Özel Bildirim Gönder</span>
          </button>

          {/* edit::Reply input */}
        </div>
        {/* end::Body */}
      </div>
    </>
  )
}

export default SingleNotification
