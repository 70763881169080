import React, {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../../_metronic/layout/core'
import {Tab} from '../Tabs/Tab'
import {TestScore} from './TestScoreReport'
import {StressReport} from './StressReport'
// import {AmCharts1} from './AmCharts-1'
// Amcharts1 farklı testlerde aynı boyutlar yoksa kullanılabilir.
import {AmCharts2} from './AmCharts-2'
// AmCharts2 Daire Grafik
import {AmCharts3} from './AmCharts-3'
// AmCharts2 Solid Grafik

const HRReport: FC = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle>{intl.formatMessage({id: 'USERCOURSEREPORTS'})}</PageTitle>
      <Tab />
      {/* <TestScore /> */}

      <div className='row g-5 g-xxl-8'>
      <div className='col-xl-12'>
      <AmCharts2 />
      </div>
      
      
      <div className='col-xl-12'>
      <AmCharts3 />
      </div>
    </div>

      
      

      <StressReport />
    </>
  )
}

export {HRReport}
