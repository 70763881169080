/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import {getQuizTotalReport} from '../Requests/_request'
import {ChartTotalQuiz} from '../../../../_metronic/partials/widgets/charts/ChartTotalQuiz'
import {TotalQuizBridge} from './TotalQuizBridge'
import {Tab} from '../report-tabs/Tab'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {GeneralQuiz} from '../course-report/GeneralQuiz'
import {useIntl} from 'react-intl'

export function TotalQuizReport() {
  const intl = useIntl()
  const [newData, setNewData] = useState<any[]>([])
  const [data, setData] = useState<any[]>([])

  useEffect(() => {
    getQuizTotalReport().then((res: any) => {
      setData(res.data)
    })
  }, [])

  console.log(data)

  return (
    <>
      <PageTitle>{intl.formatMessage({id: 'General_Reports'})}</PageTitle>
      <Tab />
      <GeneralQuiz />
      <div className='mt-16 mb-7'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0 mb-4 mt-16 p-2'>Başarı Grafikleri</h3>
        </div>
        {data.length > 0 &&
          data.map((term: any, index: number) => {
            const name = term?.totalQuizReportViewModels.map((item2: any) => item2.quiz)
            const value = term?.totalQuizReportViewModels.map((item2: any) => item2.percentage)
            const accordionId = `accordion_${index}`
            const headerId = `accordion_header_${index}`
            const bodyId = `accordion_body_${index}`

            return (
              <div className='accordion ' id={accordionId} key={index}>
                <div className='accordion-item'>
                  <h2 className='accordion-header' id={headerId}>
                    <button
                      className='accordion-button fs-4 fw-bold collapsed'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target={`#${bodyId}`}
                      aria-expanded='false'
                      aria-controls={bodyId}
                    >
                      {term.totalQuizReportViewModels[0].courseTermName}
                    </button>
                  </h2>
                  <div
                    id={bodyId}
                    className='accordion-collapse collapse'
                    aria-labelledby={headerId}
                    data-bs-parent={`#${accordionId}`}
                  >
                    <div className='accordion-body'>
                      <TotalQuizBridge className='mt-7' name={name} value={value} />
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </>
  )
}
