import React, {useState, useEffect} from 'react'
import {ChartQuiz} from '../../../../_metronic/partials/widgets/charts/ChartsQuiz'
import {getCourseTermById} from '../../courseterm/list/core/_requests'
import {UserCourseQuizCharts} from '../../../../_metronic/partials/widgets/charts/NewUserCourseQuiz'
import {CourseQuizSuccess} from '../../../../_metronic/partials/widgets/charts/NewCourseQuizSuccuess'

type Props = {
  className: string
  percentage: any
  quizName: any
  courseName: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CourseQuizBridge = ({className, percentage, quizName, courseName}: Props) => {
  return (
    <div className=' '>
      <CourseQuizSuccess
        className=''
        percentage={percentage}
        quizName={quizName}
        courseName={courseName}
      />
    </div>
  )
}

export {CourseQuizBridge}
