/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import qs from 'qs'
import {TabHr} from '../report-tabs/Tab'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {getTestDimensionReport, getTestDimensionReport2} from '../Requests/_request'
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'
import {listInventoryTest} from '../../inventorytest/list/core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

am4core.useTheme(am4themes_animated)

export const DimensionReport: FC = () => {
  const intl = useIntl()
  const [newData, setNewData] = useState<any[]>([])
  const [test, setTest] = useState<any[]>([])

  useEffect(() => {
    getTestDimensionReport2().then((res: any) => {
      console.log(res)
      let x = res?.data[0]?.dimensionReports.map((item: any) => ({
        country: item.dimensionName,
        value1: item.dimMaxScore,
        value2: item.average,
        // value3: item.finished,
      }))
      setNewData(x)
    })

    listInventoryTest('Id,Name').then((res: any) => {
      setTest(res.data)
    })
  }, [])

  const getChart = (id: any) => {
    getTestDimensionReport(id).then((res: any) => {
      console.log(res)
      let x = res?.data[0]?.dimensionReports.map((item: any) => ({
        country: item.dimensionName,
        value1: item.dimMaxScore,
        value2: item.average,
        // value3: item.finished,
      }))
      setNewData(x)
    })
  }

  console.log(newData)

  useEffect(() => {
    let chart: am4charts.XYChart | null = null

    if (newData.length > 0) {
      let chart: any = am4core.create('chartdiv958', am4charts.XYChart)
      chart.hiddenState.properties.opacity = 0 // this makes initial fade in effect

      //   chart.data = [
      //     {
      //       country: 'Max. Skor',
      //       value1: 125,
      //       value2: 525,
      //       value3: 325,
      //     },
      //     {
      //       country: 'Ort. Skor',
      //       value1: 825,
      //       value2: 225,
      //       value3: 525,
      //     },
      //   ]

      chart.data = newData

      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
      categoryAxis.renderer.grid.template.location = 0
      categoryAxis.dataFields.category = 'country'
      categoryAxis.renderer.minGridDistance = 40

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis())

      let series = chart.series.push(new am4charts.CurvedColumnSeries())
      series.dataFields.categoryX = 'country'

      series.dataFields.valueY = 'value1'
      series.tooltipText = '{valueY.value}'
      series.columns.template.strokeOpacity = 0
      series.clustered = false
      series.hiddenState.properties.visible = true // this is added in case legend is used and first series is hidden.

      let series2 = chart.series.push(new am4charts.CurvedColumnSeries())
      series2.dataFields.categoryX = 'country'

      series2.dataFields.valueY = 'value2'
      series2.tooltipText = '{valueY.value}'
      series2.columns.template.strokeOpacity = 0
      series2.clustered = false

      //   let series3 = chart.series.push(new am4charts.CurvedColumnSeries())
      //   series3.dataFields.categoryX = 'country'

      //   series3.dataFields.valueY = 'value3'
      //   series3.tooltipText = '{valueY.value}'
      //   series3.columns.template.strokeOpacity = 0
      //   series3.clustered = false

      chart.cursor = new am4charts.XYCursor()
      chart.cursor.maxTooltipDistance = 0

      chart.scrollbarX = new am4core.Scrollbar()

      series.dataItems.template.adapter.add('width', (width: any, target: any) => {
        return am4core.percent((target.valueY / valueAxis.max) * 100)
      })

      series2.dataItems.template.adapter.add('width', (width: any, target: any) => {
        return am4core.percent((target.valueY / valueAxis.max) * 100)
      })

      //   series3.dataItems.template.adapter.add('width', (width: any, target: any) => {
      //     return am4core.percent((target.valueY / valueAxis.max) * 100)
      //   })

      series.columns.template.events.on('parentset', function (event: any) {
        event.target.zIndex = valueAxis.max - event.target.dataItem.valueY
      })

      series2.columns.template.events.on('parentset', function (event: any) {
        event.target.parent = series.columnsContainer
        event.target.zIndex = valueAxis.max - event.target.dataItem.valueY
      })

      //   series3.columns.template.events.on('parentset', function (event: any) {
      //     event.target.parent = series.columnsContainer
      //     event.target.zIndex = valueAxis.max - event.target.dataItem.valueY
      //   })
    }

    return () => {
      if (chart) {
        chart.dispose()
      }
    }
  }, [newData])

  return (
    <div
      className={`card bgi-no-repeat card-xl-stretch mb-xl-8 p-10`}
      style={{
        backgroundPosition: 'right top',
        backgroundSize: '30% auto',
        backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/abstract-4.svg')})`,
      }}
    >
      {/* <PageTitle>{intl.formatMessage({id: 'MENU.HRREPORT'})}</PageTitle> */}
      {/* <TabHr /> */}

      <div className=' row d-flex flex-column px-8 mt-16 '>
        <label className='col-md-4 col-12 fw-bold fs-3 pb-8 px-16 '>Test Bilgileri</label>
        <div className='  col-md-6 col-12 px-16  '>
          <label className='required fw-bold fs-6 mb-2 '>Test Seçiniz</label>
          <select
            className='form-select form-select-solid form-select-md  '
            onChange={(e: any) => {
              getChart(e.target.value)
            }}
            // value={formik.values.inventoryTypeId}
          >
            <option value=''>{intl.formatMessage({id: 'SEÇİNİZ'})}</option>
            {test?.map((myval: any) => (
              <option value={myval?.id} key={myval?.id as any}>
                {myval?.name as any}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div id='chartdiv958' style={{width: '100%', height: '500px'}}></div>
    </div>
  )
}
