import React, {useState, useEffect} from 'react'
import {ChartTotalTopic} from '../../../../_metronic/partials/widgets/charts/ChartsTotalTopic'
import {getCourseById} from '../../course/list/core/_requests'
import {ChartTotalQuiz} from '../../../../_metronic/partials/widgets/charts/ChartTotalQuiz'
import {getCourseTermById} from '../../courseterm/list/core/_requests'
import {GeneralQuizCharts} from '../../../../_metronic/partials/widgets/charts/GeneralQuizChart'

type Props = {
  className: string
  total: any
  finished: any
  inProgress: any
  notStarted: any
  name: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const QuizBridge = ({className, notStarted, finished, inProgress, total, name}: Props) => {
  //   const [courseTermName, setCourseName] = useState()
  //   useEffect(() => {
  //     getCourseTermById(courseTermId).then((res: any) => {
  //       setCourseName(res.name)
  //     })
  //   }, [])

  return (
    <GeneralQuizCharts
      className=''
      finished={finished}
      inProgress={inProgress}
      notStarted={notStarted}
      total={total}
      name={name}
    />
  )
}

export {QuizBridge}
