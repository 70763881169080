import axios, {AxiosResponse} from 'axios'
import {Model, QueryResponse} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const THING_URL = `${API_URL}/Custom/getUserClasses`
const ANSWER_URL = `${API_URL}/Custom/getUserQuizAnswers`
const QUIZ_URL = `${API_URL}/Custom/getPercentageReport`
const TOPIC_URL = `${API_URL}/Custom/getTotalTopicReport`
const QUIZ2_URL = `${API_URL}/Custom/getTotalQuizReport`
const COURSE_QUIZ_URL = `${API_URL}/Custom/getTotalCourseQuizReport`
const Success_URL = `${API_URL}/Custom/getCourseTermSuccessReport`
const Course_Success_URL = `${API_URL}/Custom/getCourseSuccessReport`
const TEST_PERCENTAGE = `${API_URL}/Custom/getTestPercentageReport`
const TEST_DIMENSION = `${API_URL}/Custom/getTestDimensionReport`
const GENDER_REPORT = `${API_URL}/Custom/getGeneralStressReport`
const DAILY_REPORT = `${API_URL}/Custom/getDailyReport`

const getThings = (userId: any): Promise<QueryResponse> => {
  return axios
    .get(`${THING_URL}?userId=${userId}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getQuizAnswers = (userId: any, quizId: any): Promise<QueryResponse> => {
  return axios
    .get(`${ANSWER_URL}?userid=${userId}&quizid=${quizId}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getQuizReport = (): Promise<QueryResponse> => {
  return axios.get(`${QUIZ_URL}`).then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getTopicReport = (): Promise<QueryResponse> => {
  return axios.get(`${TOPIC_URL}`).then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getQuizTotalReport = (): Promise<QueryResponse> => {
  return axios.get(`${QUIZ2_URL}`).then((d: AxiosResponse<QueryResponse>) => d.data)
}
const getCourseQuizTotalReport = (): Promise<QueryResponse> => {
  return axios.get(`${COURSE_QUIZ_URL}`).then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getCourseSuccessReport = (): Promise<QueryResponse> => {
  return axios.get(`${Success_URL}`).then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getCourseSuccessReport2 = (termId: any): Promise<QueryResponse> => {
  return axios
    .get(`${Success_URL}?coursetermid=${termId}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getCourseRelatedSuccessReport = (): Promise<QueryResponse> => {
  return axios.get(`${Course_Success_URL}`).then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getCourseRelatedSuccessReport2 = (termId: any): Promise<QueryResponse> => {
  return axios
    .get(`${Course_Success_URL}?courseid=${termId}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

// Hr Raporları

const getTestPercentageReport = (journeyId: any): Promise<QueryResponse> => {
  return axios
    .get(`${TEST_PERCENTAGE}?journeyid=${journeyId}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}
const getTestPercentageReport2 = (): Promise<QueryResponse> => {
  return axios.get(`${TEST_PERCENTAGE}`).then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getTestDimensionReport = (testId: any): Promise<QueryResponse> => {
  return axios
    .get(`${TEST_DIMENSION}?testid=${testId}`)
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getTestDimensionReport2 = (): Promise<QueryResponse> => {
  return axios.get(`${TEST_DIMENSION}`).then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getGenderReport = (): Promise<QueryResponse> => {
  return axios.get(`${GENDER_REPORT}`).then((d: AxiosResponse<QueryResponse>) => d.data)
}

const getDailyReport = (): Promise<QueryResponse> => {
  return axios.get(`${DAILY_REPORT}`).then((d: AxiosResponse<QueryResponse>) => d.data)
}

export {
  getThings,
  getQuizAnswers,
  getQuizReport,
  getTopicReport,
  getQuizTotalReport,
  getCourseSuccessReport,
  getCourseSuccessReport2,
  getCourseQuizTotalReport,
  getCourseRelatedSuccessReport,
  getCourseRelatedSuccessReport2,
  getTestPercentageReport,
  getTestPercentageReport2,
  getTestDimensionReport,
  getTestDimensionReport2,
  getGenderReport,
  getDailyReport,
}
