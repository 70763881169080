import {useMemo, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate, useParams} from 'react-router-dom'
import {getThingById, addSurveyAnswer, getSurveyId} from './list/core/_requests'
import {Survey, SurveyAnswer, SurveyAnswerList} from './list/core/_models'
import {ButtonGroup} from 'react-bootstrap'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {getQuizById} from '../quiz/list/core/_requests'

const MySurveyPage = () => {
  const params = useParams()

  const intl = useIntl()

  const [survey, setSurvey] = useState<Survey>()
  const [answerPoints, setAnswerPoints] = useState<Array<number>>()
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<Array<SurveyAnswer>>([])
  const [textReturn, setTextReturn] = useState('')
  const navigate = useNavigate()
  const [total, setTotal] = useState(0)
  const [courseId, setCourseId] = useState<any>()

  useEffect(() => {
    console.log('sayfaya girdi')
    console.log(params)
    if (params != undefined) {
      getSurveyId(params.QuizId).then((id: any) => {
        getThingById(id.surveyId).then((res) => {
          setSurvey(res)
          console.log(id)
        })
      })
      getQuizById(params.QuizId).then((res2: any) => {
        setCourseId(res2.courseId)
      })
    }
  }, [params])

  useEffect(() => {
    if (survey?.answerCount != undefined) {
      const ar = []
      for (let i = 0; i < survey.answerCount; i++) {
        ar.push(i + 1)
      }

      setAnswerPoints(ar)

      let i = 0
      survey?.surveyGroup?.map((sg) => {
        i = i + (sg?.surveyQuestion?.length || 0)
      })

      setTotal(i)
    }
  }, [survey])

  const handleSendSurvey = () => {
    const salist: SurveyAnswerList = {
      surveyAnswers: options,
      answerText: textReturn,
      courseId: courseId,
    }

    addSurveyAnswer(salist).then(() => {
      navigate(`/my-quiz`)
    })
  }

  const handleRatingClick = (id?: number, rating?: number) => {
    let index = options.findIndex((option) => option.surveyQuestionId === id)
    if (index > -1) {
      options[index].answer = rating
    } else {
      options.push({
        surveyQuestionId: id,
        answer: rating,
      })
    }
    setOptions([...options])

    console.log('id: ', id, ', rating: ', rating)
    console.log('textoption: ', textReturn)
  }

  return (
    <>
      <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-1 mb-16'>
        {survey?.name}
      </h1>
      {survey?.surveyGroup?.map((sg, index) => {
        return (
          <>
            <div style={{marginBottom: '100px'}}>
              <h1
                className='fs-3'
                style={{
                  marginLeft: '12px',
                  marginTop: '40px',
                  marginBottom: '20px',
                  color: '#009ef7',
                }}
              >
                {index + 1}. {sg.name}
              </h1>
              {sg?.surveyQuestion?.map((sq, i) => {
                return (
                  <>
                    <div
                      className={`card bgi-no-repeat card-xl-stretch mb-xl-8`}
                      style={{
                        backgroundPosition: 'right top',
                        backgroundSize: '30% auto',
                        backgroundImage: `url(${toAbsoluteUrl(
                          '/media/svg/shapes/abstract-4.svg'
                        )})`,
                      }}
                    >
                      {/* begin::Body */}
                      <div
                        className='card-body'
                        style={{boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'}}
                      >
                        <a
                          href='#'
                          className='card-title fw-bold text-muted text-hover-primary fs-4'
                        >
                          {index + 1}.{i + 1}. {sq.name}
                        </a>

                        <div className='fw-bold text-primary mt-16 '>
                          {' '}
                          <ButtonGroup size='lg'>
                            {' '}
                            {answerPoints?.map((ap) => {
                              return (
                                <button
                                  onClick={(e) => {
                                    handleRatingClick(sq.id || 0, ap)
                                  }}
                                  className={
                                    options[
                                      options.findIndex(
                                        (option) => option.surveyQuestionId === sq.id
                                      )
                                    ]?.answer == ap
                                      ? 'btn btn-primary'
                                      : 'btn btn-secondary'
                                  }
                                >
                                  {' '}
                                  {ap}
                                </button>
                              )
                            })}
                          </ButtonGroup>
                        </div>

                        <p
                          className='text-dark-75 fw-semibold fs-5 m-0'
                          // dangerouslySetInnerHTML={{__html: description}}
                        ></p>
                      </div>
                      {/* end::Body */}
                    </div>

                    {/* ..................... */}
                    {/* begin::Header */}
                  </>
                )
              })}
            </div>
          </>
        )
      })}
      {survey?.hasComment && (
        <div className={`card mb-7 p-10`}>
          {/* begin::Header */}
          <div
            style={{marginLeft: '12px', marginTop: '12px'}}
            className='d-flex align-items-center mb-5'
          >
            {/* begin::User */}
            <div className='d-flex align-items-center flex-grow-1'>
              {/* begin::Avatar 
                                    <div className='symbol symbol-45px me-5'></div>*/}
              {/* end::Avatar */}

              {/* begin::Info */}
              <div className='d-flex flex-column'>
                <span className='text-gray-800 fs-4 fw-bold'>Geri Bildirimleriniz</span>
              </div>
              {/* end::Info */}
            </div>
            {/* end::User */}
          </div>
          <div style={{marginLeft: '28px', marginBottom: '28px'}}>
            <>
              <textarea
                rows={5}
                name='answertext'
                className={'form-control form-control-solid mb-3 mb-lg-0'}
                autoComplete='off'
                disabled={loading}
                value={textReturn}
                onChange={(e) => setTextReturn(e.target.value)}
              />
            </>
          </div>
        </div>
      )}
      <div className='pb-0'>
        {/* begin::Header */}
        <div style={{marginTop: '40px'}} className='align-items-center mb-5'></div>
        <div>
          <button
            type='button'
            disabled={options.length < total || loading}
            className='btn btn-warning w-100'
            onClick={handleSendSurvey}
          >
            Anketi Tamamla
          </button>
        </div>
      </div>
    </>
  )
}

export {MySurveyPage}
