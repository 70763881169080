import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import axios, {AxiosResponse} from 'axios'
import {useAuth} from '../../modules/auth'
import {ButtonGroup} from 'react-bootstrap'

const Questions = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()

  const API_URL = process.env.REACT_APP_API_URL
  const PROP_URL = `${API_URL}/UserDailyQuestion/getAll/ApplicationUserId*${currentUser?.id}?modelstoinclude=HRQuestion,ApplicationUser,HRQuestion.QuestionOption&select=Id,Name,HRQuestion.Name as QuestionName,ApplicationUser.Name as UserName,HRQuestion.QuestionOption`
  const PROP_URL2 = `${API_URL}/Custom/answerHrQuestion`
  const Reset_Cache = `${API_URL}/Custom/resetcache`
  const [questions, setquestions] = React.useState<any>([])
  const [questionsSelect, setquestionsSelect] = React.useState<any>([])
  const [trick, setTrick] = React.useState<any>(false)

  const [payload, setPayload] = React.useState<
    {questionOptionId: any; hrQuestionId: any; userId: any; score: any}[]
  >([])

  useEffect(() => {
    const ResetCache = async () => {
      return await axios.get(`${Reset_Cache}`).then(() => {
        const getQuestion = async (): Promise<any> => {
          return await axios.get(`${PROP_URL}`).then((res: AxiosResponse) => {
            setquestions(res.data.data)

            const updatedQuestions = res.data.data.map((question: any) => {
              const updatedOptions = question.questionOption.map((option: any) => {
                return {...option, isSelected: false}
              })

              return {...question, questionOption: updatedOptions}
            })
            setquestionsSelect(updatedQuestions)

            res.data.data.map((item: any) => {
              setPayload((prevPayload) => [
                ...prevPayload,
                {questionOptionId: '', hrQuestionId: '', userId: '', score: null},
              ])
            })
          })
        }
        getQuestion()
      })
    }
    ResetCache()
  }, [trick])

  const selectOption = (index: any, optionindex: any) => {
    let x = questionsSelect.map((question: any, i: number) => {
      if (i == index) {
        let updatedOptions = question.questionOption.map((option: any, r: any) => {
          if (r == optionindex) {
            return {
              ...option,
              isSelected: !option.isSelected,
            }
          } else {
            return {
              ...option,
              isSelected: false,
            }
          }
        })

        return {
          ...question,
          questionOption: updatedOptions,
        }
      } else {
        return question
      }
    })

    setquestionsSelect(x)

    setPayload(
      payload.map((item, i) => {
        if (i == index && questionsSelect[index].questionOption[optionindex].isSelected) {
          // Seçili olanın değerlerini set et
          return {
            questionOptionId: '',
            hrQuestionId: '',
            userId: '',
            score: null,
          }
        } else if (i == index) {
          return {
            questionOptionId: questions[index]?.questionOption[optionindex]?.id,
            hrQuestionId: questions[index]?.questionOption[optionindex]?.hrQuestionId,
            userId: currentUser?.id,
            score: null,
          }
        } else {
          return item // Diğer öğeleri aynı şekilde geri döndür
        }
      })
    )
  }

  // const SendAnswers = () => {
  //   payload.map((answer: any) => {
  //     const createAnswer = (prop: any): Promise<any | undefined> => {
  //       return axios
  //         .put(PROP_URL2, prop)
  //         .then((response: AxiosResponse<any>) => response.data)
  //         .then((response: any) => response.data)
  //     }
  //     createAnswer(answer).then(() => {
  //       setTrick(!trick)
  //     })
  //   })
  // }
  const SendAnswers = async () => {
    for (const answer of payload) {
      const createAnswer = async (prop: any): Promise<any | undefined> => {
        await new Promise((resolve) => setTimeout(resolve, 1)) // 0.2 saniye (200 ms) bekleme süresi
        return axios
          .put(PROP_URL2, prop)
          .then((response: AxiosResponse<any>) => response.data)
          .then((response: any) => response.data)
      }
      await createAnswer(answer)
      setTrick((trick: any) => !trick)
    }
  }

  console.log(questions)
  console.log(payload)

  return (
    <>
      {questions?.length ? (
        <div>
          <div className='card card-xl-stretch mb-xl-8  '>
            <div className='card-header align-items-center border-0 mt-3'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='fw-bolder text-dark fs-3'>Merhaba!</span>
                <span className='text-gray-400 mt-2 fw-bold fs-6'>Günlük Sorularınız...</span>
              </h3>
            </div>
            <div className='d-flex flex-column gap-7' style={{padding: '2.25rem'}}>
              {questions?.map((question: any, index: any) => {
                return (
                  <div className='d-flex flex-column flex-md-row gap-7 justify-content-md-between '>
                    <div className='d-flex flex-row mt-7 gap-2 w-100'>
                      <p className='text-dark fw-bold text-hover-primary d-block mb-1 fs-5'>
                        Soru {index + 1}- {question?.questionName}
                      </p>
                    </div>
                    <div className='d-flex flex-row justify-content-center'>
                      <div className={`d-flex flex-row  `}>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <ButtonGroup size='lg'>
                            {question?.questionOption?.map((option: any, index2: any) => {
                              return (
                                <button
                                  onClick={() => selectOption(index, index2)}
                                  className={` ${
                                    questionsSelect[index].questionOption[index2].isSelected
                                      ? 'btn btn-primary'
                                      : 'btn btn-secondary'
                                  }`}
                                >
                                  {option?.point}
                                </button>
                              )
                            })}
                          </ButtonGroup>
                          {/* <input
                                className={`form-check-input  `}
                                key={index2}
                                style={{marginBottom: '1rem'}}
                                onClick={() => selectOption(index, index2)}
                                type='checkbox'
                                value='1'
                                data-kt-check='true'
                                data-kt-check-target='.widget-13-check'
                                checked={questionsSelect[index].questionOption[index2].isSelected}
                              /> */}
                        </div>
                        {/* <p className='fw-bold text-muted'>{option?.point}</p> */}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <button
              type='submit'
              className='btn btn-primary'
              data-kt-items-modal-action='submit'
              onClick={() => SendAnswers()}
              disabled={payload.some(
                (item) =>
                  item.questionOptionId === '' ||
                  item.hrQuestionId === '' ||
                  item.userId === '' ||
                  item.score === ''
              )}
            >
              <span className='indicator-label'> {intl.formatMessage({id: 'MODALFORM.SAVE'})}</span>
            </button>
          </div>
        </div>
      ) : (
        <>
          {' '}
          <div className='card card-xl-stretch mb-xl-8  '>
            <div className='card-header align-items-center border-0 mt-3'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='fw-bolder text-dark fs-3'>Merhaba!</span>
                <span className='text-gray-400 mt-2 fw-bold fs-6'>Bugün hiç sorunuz yok</span>
              </h3>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export {Questions}
